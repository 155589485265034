import { useState, useEffect, useCallback } from "react";
import i18n from "i18next";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { withFirebase } from "../../../utils/firebase";
import { useTranslation, Trans } from "react-i18next";
import clsx from "clsx";
import { Typography, Grid, FormControl } from "@material-ui/core";
import TextField from "../../CustomizedMui/TextField";
import Select from "../../CustomizedMui/Select";
import MultiSelect from "../../CustomizedMui/MultiSelect";
import useThemeCustom from "../../../hooks/useThemeCustom";
import CheckBox from "../../CustomizedMui/Checkbox";
import Button from "../../CustomizedMui/Button";
import TermsAndConditions from "../TermsAndConditions";
import {
  nonApiSchoolFormSchema,
  year as DefaultYear,
} from "../../../utils/constant";
import { resetFormData } from "../../../Redux/action/formDataActions";
import addNewUserImage from "../../../assets/user-x 1.svg";
import useStyles from "./AboutYouNonApiFormStyles";

const AboutYouNonApiForm = ({
  isError,
  schoolData,
  submitForm,
  handleIsEmailExist,
  submitted,
  firebase
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { desktopMatch } = useThemeCustom();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(nonApiSchoolFormSchema);
  const [years, setYears] = useState();
  const [classGroups, setClasseGroups] = useState([]);
  const [isVisibleClassGroups, setIsVisibleClassGroups] = useState(false);
  const [cocurricularGroups, setCocurricularGroups] = useState([]);
  const [isVisiblecocurricularGroups, setIsVisiblecocurricularGroups] =
    useState(false);
  const [subjectGroups, setSubjectGroups] = useState([]);
  const [isVisiblesubjectGroups, setIsVisiblesubjectGroups] = useState(false);
  const formData = useSelector((state) => state.formData.formData);
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const isRTL = currentLanguage?.isRTL;

  const setFormValues = useCallback((initValues) => {
    const updatedInitialValues = {
      ...initValues,
      country: schoolData.country,
      timezone: schoolData.timezone
    }

    const classCategory = schoolData?.formData?.category?.find((data) => data.name === "Class");
    if(classCategory.isDisplay){
      setIsVisibleClassGroups(true);
      updatedInitialValues.isVisibleGroupClass = true;
      const allClassGroups = schoolData?.categories?.find((data) => data.name === "Class")?.groups?.filter((el) => !el.isHide)
      .map((data) => {
        return {
          key: data?.groupName,
          value: data?.id,
          years: data?.years,
        };
      });
      setClasseGroups(allClassGroups);
    }

    const cocurricularCategory = schoolData?.formData?.category?.find((data) => data.name === "Co-Curricular");
    if(cocurricularCategory.isDisplay){
      setIsVisiblecocurricularGroups(true);
      updatedInitialValues.isVisibleGroupCorricular = true;
      const allCorricularGroups =  schoolData?.categories?.find((data) => data.name === "Co-Curricular")?.groups?.filter((el) => !el.isHide)
      .map((data) => {
        return {
          key: data?.groupName,
          value: data?.id,
          years: data?.years,
        };
      });
      setCocurricularGroups(allCorricularGroups);
    }

    const subjectCategory = schoolData?.formData?.category?.find((data) => data.name === "Subjects");
    if(subjectCategory.isDisplay){
      updatedInitialValues.isVisibleGroupSubject = true;
      setIsVisiblesubjectGroups(true);
      const allSubjectGroups = schoolData?.categories?.find((data) => data.name === "Subjects")?.groups?.filter((el) => !el.isHide)
      .map((data) => {
        return {
          key: data?.groupName,
          value: data?.id,
          years: data?.years,
        };
      });
      setSubjectGroups(allSubjectGroups);
    }
    const yearsArray = DefaultYear?.filter((year) =>
      schoolData.freeCalendars.includes(year.value)
    ).map((year) => {
      return {
        label: year?.label,
        value: year?.value,
      };
    });
    setYears(yearsArray);
    setInitialValues(updatedInitialValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (schoolData) {
      setFormValues(initialValues)
    }
  }, [schoolData, setFormValues, initialValues]);

  useEffect(() => {
    if(!!formData && Object.values(formData).length > 0){
      setInitialValues(formData);
    }
  },[formData]);

  const languageChangeHandler = useCallback(() => {
    dispatch(resetFormData());
    setFormValues(nonApiSchoolFormSchema);
  },[dispatch, setFormValues]);

  useEffect(() => {
    i18n.on("languageChanged", languageChangeHandler)
  },[languageChangeHandler]);

  const checkEmailExist = (email) => {
    return firebase.isEmailExist(email, schoolData);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required(t("Required")),
    lastName: Yup.string().trim().required(t("Required")),
    email: Yup.string()
      .trim()
      .email(t("Invalid email address"))
      .required(t("Required")),
    phoneNo: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .trim()
      .min(9, t("Number must be a min. of 9 digits"))
      .max(11)
      .required(t("Required")),
      isVisibleGroupClass: Yup.boolean(),
      isVisibleGroupSubject: Yup.boolean(),
      isVisibleGroupCorricular: Yup.boolean(),
    childs: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().trim().required(t("Required")),
        lastName: Yup.string().trim().required(t("Required")),
        year: Yup.string().trim().required(t("Required")),
          groupClass: Yup.string().when("$isVisibleGroupClass", {
            is: true,
            then: Yup.string().trim().required(t("Required")),
            otherwise: Yup.string(),
          }),
          groupSubject: Yup.array().when("$isVisibleGroupSubject", {
            is: true,
            then: Yup.array().min(1, t("Required"))
            .required(t("Required"))
            .nullable(),
            otherwise: Yup.array()
          }),
          groupCorricular: Yup.array().when("$isVisibleGroupCorricular", {
            is: true,
            then: Yup.array().min(1, t("Required"))
            .required(t("Required"))
            .nullable(),
            otherwise: Yup.array()
          })
      })
    ),
    terms: Yup.boolean().oneOf([true], t("Required"))
  });

  return (
    <div
      className={
        desktopMatch
          ? classes.desktopAboutYouFormRoot
          : classes.aboutYouFormRoot
      }
    >
      <Typography className={classes.heading}>{t("About you")}</Typography>
      <Formik
        enableReinitialize
        initialValues={{...initialValues}}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          submitted(values.firstName, values.email);
          const isEmailExist = await checkEmailExist(values.email);
          if (isEmailExist) {
            handleIsEmailExist()
          } else {
            submitForm(values);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label={t("First name")}
                    name="firstName"
                    value={values.firstName}
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label={t("Last name")}
                    name="lastName"
                    value={values.lastName}
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label={t("Email address")}
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label={t("Phone number")}
                    name="phoneNo"
                    value={values.phoneNo}
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    error={Boolean(touched.phoneNo && errors.phoneNo)}
                    helperText={touched.phoneNo && errors.phoneNo}
                  />
                </Grid>
              </Grid>
              <FieldArray
                name="childs"
                render={({
                  move,
                  swap,
                  push,
                  insert,
                  unshift,
                  pop,
                  remove,
                }) => {
                  return (
                    <>
                     {values.childs.map((child, index) => {
                        const addNew = values.childs.length < 2;
                        const yearSelected = values.childs?.[index]?.year ? years.find(y => y.value === values.childs?.[index]?.year) : {};
                        return (

                          <div key={index}>
                            <span className={classes.space} />
                            <Grid container className={classes.childHeader}>
                              <Typography className={classes.heading}>
                              {t("Child")}({`${index + 1}`})
                              </Typography>
                              {!addNew && index !== 0 && <Grid
                                item
                                className={clsx(classes.remove, {[classes.rtlRemove]: isRTL})}
                                onClick={(e) => remove(index)}
                              >
                                <img src={addNewUserImage} alt="add_new_user" />
                                <Typography>
                                {t("Remove")}
                                </Typography>
                              </Grid>}
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                  label={t("First name")}
                                  name={`childs[${index}].firstName`}
                                  value={values.childs?.[index]?.firstName}
                                  onBlur={handleBlur}
                                  required
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.childs?.[index]?.firstName &&
                                      errors.childs?.[index]?.firstName
                                  )}
                                  helperText={
                                    touched.childs?.[index]?.firstName &&
                                    errors.childs?.[index]?.firstName
                                  }
                                />
                              </Grid>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField
                                  label={t("Last name")}
                                  name={`childs[${index}].lastName`}
                                  value={values.childs?.[index]?.lastName}
                                  onBlur={handleBlur}
                                  required
                                  onChange={handleChange}
                                  error={Boolean(
                                    touched.childs?.[index]?.lastName &&
                                      errors.childs?.[index]?.lastName
                                  )}
                                  helperText={
                                    touched.childs?.[index]?.lastName &&
                                    errors.childs?.[index]?.lastName
                                  }
                                />
                              </Grid>
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Select
                                  onChange={(e) =>{
                                    setFieldValue(
                                      `childs[${index}].year`,
                                      e.target.value
                                    )
                                    setFieldValue(
                                      `childs[${index}].groupClass`,
                                      ""
                                    )
                                    setFieldValue(
                                      `childs[${index}].groupCorricular`,
                                      []
                                    )
                                    setFieldValue(
                                      `childs[${index}].groupSubject`,
                                      []
                                    )
                                  }
                                  }
                                  handleBlur={handleBlur}
                                  value={values.childs?.[index]?.year}
                                  name={`childs[${index}].year`}
                                  label={t("Year")}
                                  error={Boolean(
                                    touched.childs?.[index]?.year &&
                                      errors.childs?.[index]?.year
                                  )}
                                  helperText={
                                    touched.childs?.[index]?.year &&
                                    errors.childs?.[index]?.year
                                  }
                                  id="year-select-outlined"
                                  labelId="year-select-outlined-label"
                                  required
                                  array={years}
                                />
                              </Grid>
                              {values.childs?.[index]?.year && isVisibleClassGroups && <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Select
                                  onChange={(e) => {
                                    setFieldValue(
                                      `childs[${index}].groupClass`,
                                      e.target.value
                                    );
                                    handleChange(e);
                                  }}
                                  handleBlur={handleBlur}
                                  value={values.childs?.[index]?.groupClass}
                                  name={`childs[${index}].groupClass`}
                                  label={t("Class (single-select)")}
                                  error={Boolean(
                                    touched.childs?.[index]?.groupClass &&
                                      errors.childs?.[index]?.groupClass
                                  )}
                                  helperText={
                                    touched.childs?.[index]?.groupClass &&
                                    errors.childs?.[index]?.groupClass
                                  }
                                  id="class-select-outlined"
                                  labelId="class-select-outlined-label"
                                  required
                                  array={classGroups.filter(g => g.years.includes(yearSelected?.label))}
                                />
                              </Grid>}
                              {values.childs?.[index]?.year && isVisiblecocurricularGroups && <Grid item lg={12} md={12} sm={12} xs={12}>
                                <MultiSelect
                                  onChange={(e, value) => {
                                    setFieldValue(
                                      `childs[${index}].groupCorricular`,
                                      value
                                    );
                                  }}
                                  handleBlur={handleBlur}
                                  value={
                                    values.childs?.[index]?.groupCorricular
                                  }
                                  name={`childs[${index}].groupCorricular`}
                                  label={t("Extra activities (multi-select)")}
                                  error={Boolean(
                                    touched.childs?.[index]?.groupCorricular &&
                                      errors.childs?.[index]?.groupCorricular
                                  )}
                                  helperText={
                                    touched.childs?.[index]?.groupCorricular &&
                                    errors.childs?.[index]?.groupCorricular
                                  }
                                  id="extra-select-outlined"
                                  labelId="extra-select-outlined-label"
                                  required
                                  array={cocurricularGroups.filter(g => g.years.includes(yearSelected?.label))}
                                />
                              </Grid>}
                              {values.childs?.[index]?.year && isVisiblesubjectGroups && <Grid item lg={12} md={12} sm={12} xs={12}>
                                <MultiSelect
                                  onChange={(e, value) => {
                                    setFieldValue(
                                      `childs[${index}].groupSubject`,
                                      value
                                    );
                                  }}
                                  handleBlur={handleBlur}
                                  value={values.childs?.[index]?.groupSubject}
                                  name={`childs[${index}].groupSubject`}
                                  label={t("Subject (multi-select)")}
                                  error={Boolean(
                                    touched.childs?.[index]?.groupSubject &&
                                      errors.childs?.[index]?.groupSubject
                                  )}
                                  helperText={
                                    touched.childs?.[index]?.groupSubject &&
                                    errors.childs?.[index]?.groupSubject
                                  }
                                  id="subjects-select-outlined"
                                  labelId="subjects-select-outlined-label"
                                  required
                                  array={subjectGroups.filter(g => g.years.includes(yearSelected?.label))}
                                  multiSelect
                                />
                              </Grid>}
                            </Grid>
                            {(values.childs?.length === (index + 1)) && <div className={classes.addNew} onClick={() => push(nonApiSchoolFormSchema.childs[0])}>
                              <Typography>
                              {t("Add another child")}
                              </Typography>
                            </div>}
                          </div>
                        );
                      })}
                    </>
                  );
                }}
              />
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.heading}>
                    {t("Protecting your privacy")}
                  </Typography>
                  <TermsAndConditions isError={isError} />
                </Grid>
                <CheckBox
                  checked={values.terms}
                  handleTermCheck={() => setFieldValue("terms", !values.terms)}
                  error={touched.terms && errors.terms}
                  condition={
                    <Trans i18nKey="I agree to the terms and conditions"
                    values={{ privacy: t("Privacy Policy"), terms: t("Terms & Conditions")}}
                    components={{
                      a1: <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BASE_URL}/privacy-policy`}>{t("Privacy Policy")}</a>,
                      a2:  <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BASE_URL}/school-terms-and-conditions`}>{t("Terms & Conditions")}</a>
                      }}/>
                  }
                />
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth>
                    <Button onClick={handleSubmit}>
                    {t("Register")}
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default withFirebase(AboutYouNonApiForm);
