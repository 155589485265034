import { Skeleton } from "@mui/material";
import useThemeCustom from "../../../hooks/useThemeCustom";
import DashboardHeader from "../../DashboardHeader";
import FooterLinks from "../../FooterLinks";
import useStyles from "./AboutYouSkeletonStyles";
import formStyles from "../../FormSection/FormSectionStyles";

const AboutYouSkeleton = () => {
  const classes = useStyles();
  const formClasses = formStyles();
  const { desktopMatch } = useThemeCustom();

  return (
    <div
      className={desktopMatch ? formClasses.desktopContainer : formClasses.container}
    >
    <DashboardHeader />
    <div className={desktopMatch ? formClasses.desktopFormRoot : formClasses.formRoot}>
    <div
      className={
        desktopMatch ? formClasses.desktopApiSection : formClasses.apiSection
      }
    >

      <div
        className={desktopMatch ? classes.desktopSchoolInfo : classes.schoolInfo}
      >
        <Skeleton
          className={classes.skeleton}
          variant="rounded"
          width={180}
          height={180}
        />
        <Skeleton variant="rounded" width={207} height={32} />
        <Skeleton variant="rounded" width={360} height={16} />
        <Skeleton variant="rounded" width={360} height={16} />
        <Skeleton variant="rounded" width={360} height={16} />
      </div>
      <div className={desktopMatch ? classes.desktopSchoolMain : classes.main}>
        <div>
          <Skeleton variant="rounded" width={142} height={28} />
          <Skeleton variant="rounded" width={436} height={32} />
          <Skeleton variant="rounded" width={436} height={32} />
          <Skeleton variant="rounded" width={436} height={32} />
          <Skeleton variant="rounded" width={436} height={32} />
        </div>
      </div>

    </div>
    {desktopMatch && <FooterLinks />}
    </div>
    </div>
  );
};

export default AboutYouSkeleton;
