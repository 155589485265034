import axios from "axios";

const registerWithApiSchool = (formData) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/schoolBytes/family/create`, formData)
}

axios.interceptors.request.use(
    async(config) => {
      const headers = config.headers;
      const token = localStorage.getItem("token");

      if(!token){
        const response = await generateToken();
        localStorage.setItem("token", response.token);
      }

      return {
        ...config,
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`
        }
      };
    },
    (error) => {
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(async response => {
    return response;
  }, async (error) => {
    const originalRequest = error.config;
    // Handled expired token
    if(error.response.status === 401 && !originalRequest._retry){
        originalRequest._retry = true;
        const newToken = await generateToken();
        localStorage.setItem("token", newToken.token);
      if (newToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return axios(originalRequest);
      }
    }
});

const generateToken = async () => {
    try{
        const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/generate-FE-token`, {
            method: "POST"
        }).then(res => res.json())
        return response
    } catch(error) {
        console.error(error)
        throw error
    }
}

const findSchoolByUrl = async (url) => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/school-url/${url}`)
        return response?.data
    } catch(error) {
        console.error(error)
        throw error
    }
}

const fetchLanguageList = async() => {
    try{
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/get-languages`)
        return response?.data
    }catch(error) {
        console.error(error)
        throw error
    }
}

const getMaintenanceMode = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/maintenance/mode`)
        return response?.data
    } catch(error) {
        console.error(error)
        throw error
    }
}

const saveEmailToNotify = (formData) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/maintenance/visitor/add`, formData)
}

const isEmailExist =  (formData) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/member/isEmailExist`, formData)
};

const registerMember = (formData) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/admin/member/create`, formData)
};

export {
    registerWithApiSchool,
    findSchoolByUrl,
    fetchLanguageList,
    getMaintenanceMode,
    saveEmailToNotify,
    isEmailExist,
    registerMember
}