import { useTranslation, Trans } from "react-i18next";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../utils/constant";

const useStyles = makeStyles(() => ({
  scrollableWindow: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    "& a": {
      textDecoration: "none",
      color: colors.primary,
    },
    "& p": {
      marginBottom: "18px",
    },
    "& p:last-child": {
      marginBottom: "0px",
    },
    "& .bold": {
      fontWeight: "bold",
    },
  },
  termsAndConditons: {
    height: "132px",
    border: `1px solid ${colors.border}`,
    borderRadius: "4px",
    padding: "9px 9px 8px 8px",
    "& p": {
      fontSize: "14px",
      textAlign: "start",
      lineHeight: 1.71,
      color: colors.border3,
      "@media (max-width:460px)": {
        fontSize: "12px",
      },
    },
    marginBottom: "10px",
    "& ::-webkit-scrollbar": {
      display: "initial !important",
      width: "4px"
    },
    "& ::-webkit-scrollbar-track":{
      background: `${colors.border2} !important`
    },
    "& ::-webkit-scrollbar-thumb":{
      background: `${colors.primary} !important`,
    },
  },
}));

const TermsAndConditions = ({ isError }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div
      className={classes.termsAndConditons}
    >
      <div className={classes.scrollableWindow}>
        <Typography>
          <Trans  i18nKey="We only collect, hold, use and disclose your personal information to provide our services to you. Our Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us. You can view our full Privacy Policy"
          values={{ link: t("here")}}
          components={{ a: <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/school-terms-and-conditions`}rel="noreferrer">{t("here")}</a> }}/>
        </Typography>
        <Typography>
          <Trans  i18nKey="We agree not to disclose your confidential information to any third party other than as required to provide the service, receive our own professional advice or as otherwise may be legally required by us. You can view our full Terms and Conditions"
          values={{ link: t("here")}}
          components={{ a: <a target="_blank" href={`${process.env.REACT_APP_BASE_URL}/school-terms-and-conditions`}rel="noreferrer">{t("here")}</a> }}/>
        </Typography>
        <Typography>
          {t("When you use the School Calendar Organiser app, we require that you fill out a registration form and accept our Terms and Conditions.")}
        </Typography>
        <Typography>
          {t("While we have established a quality assurance process and take all reasonable steps to create correct App entries, you understand that we cannot guarantee that all App entries are 100% correct. You also understand that you cannot edit the school calendar events or school tasks.")}
        </Typography>
        <Typography className="bold">
          {t("By signing up for our services you acknowledge our Privacy Policy (below) and you agree to use our services in accordance with our Terms and Conditions (below).")}
        </Typography>
        <Typography>
          {t("Privacy Policy")}:
          <br />
          <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BASE_URL}/privacy-policy`}>
            {`${process.env.REACT_APP_BASE_URL}/privacy-policy`}
          </a>
          <br />
          {t("Terms and Conditions")}:
          <br />
          <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BASE_URL}/school-terms-and-conditions`}>
            {`${process.env.REACT_APP_BASE_URL}/school-terms-and-conditions`}
          </a>
        </Typography>
        <Typography>
        {t("We look forward to having your back!")}
        </Typography>
      </div>
    </div>
  );
};

export default TermsAndConditions;
