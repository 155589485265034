import { useSelector } from "react-redux";
import { MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { Select, FormControl, InputLabel } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../../utils/constant";

const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
    height: "48px",
    textAlign: "start",
    padding: "12px 0",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${colors.border} !important`,
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "12px 32px 12px 12px",
    },
  },
  rtlSelect: {
    width: "100%",
    height: "48px",
    textAlign: "start",
    padding: "12px 0",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${colors.border} !important`,
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "12px 32px 12px 12px",
    },
    "& legend": {
      textAlign: "end",
    },
    "& svg": {
      right: "auto !important",
      left: "7px !important",
    },
  },
  selectLabel: {
    transform: "translate(14px, 16px) scale(1)",
    fontSize: "16px",
    fontWeight: "500 !important",
    lineHeight: "18px !important",
    color: `${colors.border3} !important`,
    "@media (max-width:460px)": {
      fontSize: "14px !important",
      transform: "translate(15px, -5px) scale(0.85)",
    },
  },
  rtlSelectLabel: {
    transform: "translate(-14px, 16px) scale(1) !important",
    left: "auto !important",
    right: "0",
    transformOrigin: "top right !important",
  },
  error: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: 1.67,
    color: colors.tertiaryRed,
    textAlign: "start",
    margin: "4px 0 0",
  },
  errorBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${colors.tertiaryRed} !important`,
    },
  },
  selectInput: {
    fontSize: "16px",
    "@media (max-width:460px)": {
      fontSize: "14px !important",
    },
  },
  listItem: {
    whiteSpace: "break-spaces",
    maxWidth: "436px",
  },
  shrink: {
    color: `${colors.typography1} !important`,
  },
  rtlShrink: {
    color: `${colors.typography1} !important`,
    transform: "translate(-14px, -6px) scale(0.75) !important",
    left: "auto !important",
    right: "0",
    transformOrigin: "top right !important",
  },
  focused: {
    color: `${colors.primary} !important`,
  },
}));

const SelectContainer = ({
  startAdornment,
  onChange,
  value,
  label,
  error,
  id,
  labelId,
  name,
  helperText,
  handleBlur,
  required,
  array,
}) => {
  const classes = useStyles();
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const isRTL = currentLanguage?.isRTL;
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel
        required={required}
        variant="outlined"
        id={labelId}
        className={clsx(classes.selectLabel, {
          [classes.rtlSelectLabel]: isRTL,
        }, "responsive-lineHeight")}
        classes={{
          shrink: isRTL ? classes.rtlShrink : classes.shrink,
          focused: classes.focused,
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        variant="outlined"
        id={id}
        value={value}
        onChange={onChange}
        classes={{ error: clsx({ [classes.errorBorder]: error }) }}
        className={isRTL ? classes.rtlSelect : classes.select}
        onBlur={handleBlur}
        error={error}
        name={name}
        label={label}
        IconComponent={KeyboardArrowDownIcon}
        startAdornment={startAdornment}
        inputProps={{ className: classes.selectInput }}
      >
        <MenuItem value="" dir={isRTL ? "rtl" : "ltr"}>
          <em>{label}</em>
        </MenuItem>
        {!!array &&
          array.length > 0 &&
          array.map(function (object) {
            const obValue = object.value;
            const obLabel =
              object.label || object.name || object.key || object.text;
            return (
              <MenuItem
                className={classes.listItem}
                key={obValue}
                value={obValue}
              >
                {obLabel}
              </MenuItem>
            );
          })}
      </Select>
      {Boolean(error) ? (
        <span style={{ height: 20 }} className={classes.error}>
          {helperText}
        </span>
      ) : (
        ""
      )}
    </FormControl>
  );
};

export default SelectContainer;
