import { Typography } from "@material-ui/core";
import useThemeCustom from "../../../hooks/useThemeCustom";
import aboutIcon from "../../../assets/aboutIcon.png";
import DownloadApp from "../DownloadApp";
import useStyles from "./SuccessScreenStyles";
import SuccessConfetti from "../../SuccessConfetti";

const SuccessScreen = ({
  heading,
  description1,
  description2,
  instruction,
}) => {
  const classes = useStyles();
  const { desktopMatch } = useThemeCustom();

  return (
    <div className={desktopMatch ? classes.desktopSuccessScreenRoot : classes.successScreenRoot}>
      <Typography className={classes.heading}>{heading}</Typography>
      <Typography className={classes.description}>{description1}</Typography>
      <Typography className={classes.description}>{description2}</Typography>
      <DownloadApp />
      <img className={classes.aboutIcon} src={aboutIcon} alt="about_icon" />
      <Typography className={classes.instructions}>{instruction}</Typography>
      <SuccessConfetti/>
    </div>
  );
};

export default SuccessScreen;
