import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../utils/constant";

const useStyles = makeStyles(() => ({
  desktopAboutYouFormRoot: {
    padding: "32px 96px 0px 124px",
    overflow: "auto",
    width: "436px"
  },
  aboutYouFormRoot: {
    margin: "24px auto",
    maxWidth: "436px",
    "@media (max-width:570px)": {
      width: "calc(100vw - 40px)",
    }
  },
  childHeader: {
    display: "inline-flex",
    justifyContent: "space-between",
  },
  heading: {
    textAlign: "start",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "24px",
    lineHeight: 1.2,
    "@media screen and (max-device-width:599px)": {
      fontSize: "20px",
      lineHeight: 1.5,
    },
  },
  space: {
    height: "28px",
    display: "block",
  },
  remove: {
    display: "inline-flex",
    cursor: "pointer",
    justifyContent: "end",
    height: "24px",
    "& p": {
      fontSize: "14px",
      lineHeight: 1.71,
      fontWeight: "600",
      color: colors.tertiaryRed,
      opacity: 0.6,
    },
    "& img": {
      marginRight: "8px",
    },
  },
  rtlRemove:{
    "& img": {
      marginRight: "0px !important",
      marginLeft: "8px !important"
    },
  },
  addNew: {
    display: "inline-flex",
    cursor: "pointer",
    marginTop: "28px",
    width: "100%",
    marginBottom: "28px",
    background: colors.primaryExtraLightGrey,
    borderRadius: "4px",
    border: `1px solid ${colors.border}`,
    "& p": {
      margin: "12px auto",
      fontSize: "14px",
      lineHeight: 1.71,
      fontWeight: "bold",
      color: colors.primaryDark
    },
  }
}));

export default useStyles;
