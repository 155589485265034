import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/constant";
const useStyles = makeStyles(() => ({
  desktopContainer: {
    background: colors.rootBackground,
    height: "100vh",
    overflow: "auto",
    "@media screen and (min-device-width: 1280px) and (max-device-height: 820px)": {
      height: "auto !important",
    },
  },
  container: {
    background: colors.rootBackground,
    height: "auto",
    overflow: "auto",
  },
  desktopFormRoot:{
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100% - 96px)"
  },
  formRoot: {
    display: "flex",
    flexFlow: "column",
    height: "calc(100% - 114px)",
    marginTop: "114px",   //remove dropdown
    //marginTop: "80px",
    // "@media screen and (max-device-width:599px)": {
    //   marginTop: "70px",
    // },
    // "@media screen and (max-device-width:280px)": {
    //   marginTop: "90px",
    // },
    background: colors.white,
  },
  desktopApiSection: {
    height: "664px",
    width: "1064px",
    background: "white",
    padding: "8px",
    display: "inline-flex",
    borderRadius: "20px",
    maxHeight: "calc(100vh - 192px)",
    "& ::-webkit-scrollbar": {
      width: "4px"
    },
    "& ::-webkit-scrollbar-track":{
      background: colors.primaryExtraLightGrey
    },
    "& ::-webkit-scrollbar-thumb":{
      background: colors.primaryLightGrey,
    },
    "@media screen and (max-device-width:1280px) and (max-device-height:832px)": {
      height: "604px",
    },
    boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.1)",
  },
  apiSection: {
    height: "fit-content",
    width: "100%",
    background: "white",
    borderRadius: "none !important",
    "& ::-webkit-scrollbar": {
      width: "4px"
    },
    "& ::-webkit-scrollbar-track":{
      background: colors.primaryExtraLightGrey
    },
    "& ::-webkit-scrollbar-thumb":{
      background: colors.primaryLightGrey,
    },
  },
}));

export default useStyles;
