import { useSelector } from "react-redux";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import useThemeCustom from "../../../hooks/useThemeCustom";
import appStoreImage from "../../../assets/appStoreSmall.svg";
import playStoreImage from "../../../assets/playStoreSmall.svg";
import qrCode from "../../../assets/newQRCodeIcon.svg";
import appStoreMobileImage from "../../../assets/appleStoreMobile.svg";
import playStoreMobileImage from "../../../assets/googleStoreMobile.svg";
import useStyles from "./DownloadStyles";

const DownloadApp = () => {
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const { mobileMatch } = useThemeCustom();
  const classes = useStyles();
  return (
    <>
      {mobileMatch ? (
        <div className={classes.downloadAppMobile}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/au/app/my-family-hand/id1528123918"
          >
            <img src={appStoreMobileImage} alt="app_store_mobile" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.mywhanau.calendar"
          >
            <img src={playStoreMobileImage} alt="play_store_mobile" />
          </a>
        </div>
      ) : (
        <div className={classes.downloadApp}>
          <div className={clsx(classes.section1, {[classes.rtlDownloadAppSection1]: currentLanguage?.isRTL})}>
            <Typography className={classes.heading2}>
              Scan QR code to
            </Typography>
            <Typography className={classes.heading3}>Download app</Typography>
            <Typography className={classes.heading4}>
              Or click below...
            </Typography>
            <div className={clsx(classes.storeLinks, {[classes.rtlStoreLinkes]: currentLanguage?.isRTL})}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://apps.apple.com/au/app/my-family-hand/id1528123918"
              >
                <img alt="app_store_link" src={appStoreImage} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.mywhanau.calendar"
              >
                <img alt="play_store_link" src={playStoreImage} />
              </a>
            </div>
          </div>
          <img alt="qr_code" src={qrCode} />
        </div>
      )}
    </>
  );
};
export default DownloadApp;
