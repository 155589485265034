const initialState={
    mode: false
}

const maintenanceMode = (state= initialState, action) =>{
    switch(action.type){
        case 'SET_MAINTENANCE_MODE' : return {...state, mode: action.maintenanceMode}
        default: return state
    }
}
export default maintenanceMode