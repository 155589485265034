import { makeStyles } from "@material-ui/styles";
import { colors } from "../../utils/constant";

const useStyles = makeStyles(() => ({
  imageContainer: {
    maxHeight: "156px",
    maxWidth: "360px",
    "@media (max-width:430px)": {
      width: "85vw",
    },
    "& svg": {
      borderRadius: "16px",
      width: "156px !important",
      height: "156px !important",
    }
  },
  desktopImageContainer: {
    maxHeight: "180px",
    maxWidth: "360px",
    "& svg": {
      borderRadius: "16px",
      width: "180px",
      height: "180px",
    },
  },
  desktopSchoolInfo: {
    minWidth: "344px",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    padding: "40px 42px",
    borderRadius: "20px",
    overflowY: "auto",
    overflowX: "hidden",
    "& .lazyLoadContainer":{
      height: "180px",
      width: "360px",
      display: "flex",
      justifyContent: "center"
    },
    "& img":{
      objectFit: "contain"
    },
    "& .schoolInfo-2, .schoolInfo-3, .schoolInfo-4": {
      width: "360px",
    },
    "& .schoolInfo-2": {
      margin: "16px auto",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: 1.2,
    },
    "& .schoolInfo-3": {
      fontWeight: 400,
    },
    "& .schoolInfo-3, .schoolInfo-4": {
      fontSize: "16px",
      lineHeight: "28px",
    },
    "& .schoolInfo-4": {
      fontWeight: 500,
      marginTop: "16px",
    }
  },
  schoolInfo: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    padding: "40px 42px",
    "& .lazyLoadContainer":{
      height: "156px",
      width: "360px",
      display: "flex",
      justifyContent: "center",
      "@media (max-width:430px)": {
        width: "85vw",
      }
    },
    "& img":{
      objectFit: "contain"
    },
    "& .schoolInfo-2, .schoolInfo-4": {
      width: "360px",
    },
    "& .schoolInfo-3": {
      width: "436px",
      fontWeight: 400,
      "@media (max-width:460px)": {
        width: "calc(100vw - 50px)",
        fontSize: "14px",
      },
    },
    "& .schoolInfo-2": {
      margin: "16px auto",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "24px",
      "@media (max-width:460px)": {
        width: "auto",
        fontSize: "16px",
      },
    },
    "& .schoolInfo-3, .schoolInfo-4": {
      fontSize: "16px",
      lineHeight: "28px",
    },
    "& .schoolInfo-4": {
      fontWeight: 500,
      marginTop: "16px",
      "@media (max-width:460px)": {
        width: "auto",
        fontSize: "14px",
      },
    }
  },
  hide:{
    display: "none"
  },
  fonts:{
    "& .schoolInfo-1": {
      width: "250px",
      fontSize: "24px",
      fontWeight: "bold",
      marginTop: "32px",
      lineHeight: 1.33,
    },
    "& .schoolInfo-2, .schoolInfo-3, .schoolInfo-4":{
      color: colors.primaryDark
    }
  }
}));

export default useStyles;
