import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../utils/constant";

const useStyles = makeStyles(() => ({
  downloadApp: {
    border: `1px solid ${colors.primary}`,
    borderRadius: "8px",
    width: "404px",
    height: "140px",
    padding: "16px",
    display: "inline-flex",
    marginTop: "32px",
    marginBottom: "32px",
    position: "relative",
    zIndex: 1,
    "& *":{
      fontFamily: "Noto-sans !important"
    }
  },
  section1: {
    width: "204px",
    marginRight: "64px",
  },
  rtlDownloadAppSection1:{
    marginRight: "0px",
    marginLeft: "64px",
  },
  heading2: {
    fontSize: "20px",
    fontWeight: 500,
  },
  heading3: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    lineHeight: "33.6px",
  },
  heading4: {
    fontSize: "14px",
    marginBottom: "8px",
  },
  storeLinks: {
    display: "inline-flex",
    "& img:first-child": {
      marginRight: "8px",
    },
  },
  rtlStoreLinkes:{
    "& img:first-child": {
      marginLeft: "8px",
      marginRight: "0px",
    },
  },
  downloadAppMobile: {
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    marginTop: "32px",
    "& img": {
      height: "64px",
      width: "218.7px",
    },
    "& img:first-child": {
      marginBottom: "36px",
    },
  },
}));

export default useStyles;
