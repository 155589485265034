import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/constant";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    position: "fixed",
    zIndex: "2",
    "& .helpFrame": {
      height: "48px",
      width: "48px",
    },
  },
  logo: {
    height: 48,
    "@media screen and (max-device-width:599px)": {
      height: 38,
    },
  },
  desktopRoot: {
    width: "100%",
    height: "96px",
    backgroundColor: colors.rootBackground,
    "& .helpFrame": {
      height: "48px",
      width: "48px",
    },
  },
  headerTop: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "calc(100% - 64px)",
    padding: "16px 32px",
    backgroundColor: colors.dark,
    "@media screen and (max-device-width:599px)": {
      padding: "16px 20px",
      width: "calc(100% - 40px)",
    },
    "@media (max-width:280px)": {
      display: "flex",
      flexFlow: "column",
      "& img": {
        width: "178px !important",
        height: "38px !important",
      },
    },
  },
  desktopHeaderTop: {
    display: "inline-flex",
    justifyContent: "space-between",
    width: "calc(100% - 64px)",
    padding: "24px 32px",
  },
  desktopSelectForm: {
    width: "232px !important",
    height: "44px",
    borderRadius: "100px !important",
    background: colors.white,
    "& .MuiOutlinedInput-root": {
      borderRadius: "100px !important",
    },
    "& fieldset": {
      borderColor: `${colors.border} !important`,
    },
  },
  selectForm: {
    width: "100%",
    height: "44px",
    borderRadius: "0px !important",
    "& .MuiOutlinedInput-root": {
      height: "44px",
      background: colors.background,
    },
    "& .MuiSelect-nativeInput": {
      width: "auto !important",
    },
    "& fieldset": {
      border: "none !important",
    },
    "& img": {
      marginRight: "12px",
      marginLeft: "18px",
      "@media screen and (max-device-width:599px)": {
        marginLeft: "4px !important",
      },
    },
  },
  rtlSelectForm: {
    "& .MuiSelect-iconOutlined": {
      right: "auto !important",
      left: "7px !important",
    },
    "& .translateIcon": {
      marginRight: "14px",
    },
    "& .MuiSelect-select": {
      paddingRight: "0px !important",
    },
  },
  desktopHelpSection: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    minWidth: "142px",
    justifyContent: "end",
    "& p": {
      fontWeight: "bold",
      fontSize: "14px",
      marginRight: "16px",
      lineHeight: "28px"
    },
  },
  equalWidth:{
    flex: 1,
    display: "inline-flex"
  },
  mobileHelpSection: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    "@media (max-width:450px)": {
      marginRight: "0px",
      marginLeft: "auto",
    },
    "& p": {
      fontWeight: "bold",
      fontSize: "14px",
      color: `${colors.tertiaryConfirm} !important`,
    },
  },
  rtlMobileHelpSection:{
    "@media (max-width:450px)": {
      marginRight: "0px !important",
      marginLeft: "0px !important",
      "& p":{
        marginLeft: "0px !important"
      }
    },
  },
  helpSection: {
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
    "& p": {
      fontWeight: "bold",
      fontSize: "16px",
      marginRight: "16px",
      color: `${colors.white} !important`,
    },
  },
  rtlHelSection: {
    "& p": {
      marginRight: "0px",
      marginLeft: "16px",
    },
  },
  select: {
    "& *":{
      fontFamily: "Noto-sans !important"
    },
    height: "44px",
    "& input": {
      height: "44px",
    },
    "& span": {
      fontWeight: "bold",
      fontSize: "14px",
      display: "flex",
      textAlign: "start",
    },
    "& li": {
      "@media screen and (max-device-width:1279px)": {
        padding: "0px",
      },
    },
    "& .translateIcon": {
      height: "24px",
    },
    "& svg": {
      "@media screen and (max-device-width:1279px)": {
        marginRight: "24px",
      },
      "@media screen and (max-device-width:599px)": {
        marginRight: "10px",
      },
    },
  },
  rtlSelect:{
    "& svg": {
      "@media screen and (max-device-width:1279px)": {
        marginRight: "0px !important",
        marginLeft: "24px",
      },
      "@media screen and (max-device-width:599px)": {
        marginRight: "0px !important",
        marginLeft: "10px",
      },
    },
  },
  paper: {
    top: "124px !important",
    left: "auto !important",
    boxShadow: "none !important",
    borderTopLeftRadius: "0px !important",
    borderTopRightRadius: "0px !important",
    "@media screen and (max-device-width:599px)": {
      top: "114px !important",
    },
    "@media screen and (max-device-width:280px)": {
      top: "135px !important",
    },
  },
  desktopPaper: {
    marginTop: "24px",
    boxShadow: "none !important",
    borderRadius: "8px !important",
    "& li":{
      // width: "216px !important"
    }
  },
  helpMenu: {
    background: colors.dark,
    minWidth: "fit-content",
    padding: "28px 20px",
    width: "400px",
    borderRadius: "20px !important",
    "& *": {
      color: colors.white,
      lineHeight: "28px",
    },
    "& .supportEmail": {
      "& a": {
        textDecoration: "none",
        color: colors.primary,
        fontSize: "14px",
      },
    },

    "@media screen and (max-device-width:1279px)": {
      width: "auto !important",
      maxHeight: "78vh",
      overflow: "auto"
    },
    "@media (max-width:465px)": {
      width: "auto",
      maxHeight: "80vh",
    },
  },
  helpMenuContainer: {
    display: "inline-flex",
    alignItems: "center",
    marginBottom: "16px",
    "& .supportPerson": {
      fontSize: "20px",
      lineHeight: 1.2,
    },
    "& p": {
      marginLeft: "24px",
      fontSize: "20px",
      lineHeight: 1.2,
      fontWeight: "bold",
      "@media screen and (max-device-width:599px)": {
        fontSize: "14px",
        lineHeight: 2,
      },
    },
  },
  helpMenuContainerRtl:{
    "& .highlightImg":{
      left: 0
    },
    "& p": {
      marginLeft: "auto !important",
      marginRight: "24px !important",
    }
  },
  supportPerson: {
    position: "relative",
    height: "56px",
    width: "60px",
    "& .highlightImg": {
      position: "absolute",
      marginRight: "4px",
    },
    "& .michelleImg": {
      height: "48px",
      marginLeft: "12px",
      marginTop: "8px",
      borderRadius: "50%",
      border: "solid 2px rgba(223, 223, 223, 0.5)",
    },
  },
  supportPersonImage: {
    "& svg": {
      height: "48px",
      width: "48px",
      marginLeft: "12px",
      marginTop: "8px",
      borderRadius: "50%",
    },
  },
  desktopPaperClass: {
    top: "95px !important",
    left: "auto !important",
    right: "44px",
    zIndex: 2,
  },
  desktopPaperClassRtl:{
    top: "95px !important",
    right: "auto !important",
    left: "44px !important",
    zIndex: 2,
    direction: "rtl !important"
  },
  paperClass: {
    top: "80px !important",
    left: "0px !important",
    right: "0px",
    boxShadow: "none !important",
    maxWidth: "100vw !important",
    borderBottomLeftRadius: "20px !important",
    borderBottomRightRadius: "20px !important",
    backgroundColor: `${colors.dark} !important`,
    zIndex: 2,
    "@media (max-width:465px)": {
      maxHeight: "calc(100vh - 128px)",
      //   width: "100vw",
    },
    "@media screen and (max-device-width:599px)": {
      top: "69px !important",
    },
  },
  apiSection: {
    background: colors.rootBackground,
    borderRadius: "12px",
    padding: "18px",
    marginTop: "32px",
    "& *": {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: 1.75,
      color: colors.dark,
      "@media screen and (max-device-width:599px)": {
        fontSize: "14px",
        lineHeight: 2,
      },
    },
    "& p": {
      marginBottom: "12px",
    },
    "& p:first-child": {
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: 1.2,
    },
    "& p:last-child": {
      marginBottom: "0",
    },
  },
  links: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "center",
  },
  list: {
    "& *":{
      fontFamily: "Noto-sans !important"
    },
    background: `${colors.dark} !important`,
    color: colors.white,
    padding: "4px 16px 0px 16px !important",
    // maxWidth: "772px !important",
    height: "417px",
    display: "flex",
    flexFlow: "column",
    flexWrap: "wrap",
    "& li .label": {
      display: "flex",
      gap: '16px',
      justifyContent: "space-between",
    },
    "@media screen and (max-device-width:1279px)": {
      padding: "4px 0px 0px 0px !important",
      maxWidth: "100% !important",
      "& li .label": {
        marginLeft: "16px",
        "& .selectedLanguageCheck":{
          marginRight: "16px"
        }
      },
      width: "100vw !important",
    },
    "@media screen and (max-device-width:599px)": {
      flexWrap: "nowrap !important",
      height: "auto",
      padding: "0px !important",
      "& li .label": {
        marginLeft: "6px",
        "& .selectedLanguageCheck":{
          marginRight: "2px !important"
        }
      },
    },
  },
  nonPaidList:{
    height: "284px !important",
    "@media screen and (max-device-width:599px)": {
      height: "auto !important",
    }
  },
  selected: {
    color: colors.primary,
    "& span": {
      color: `${colors.primary} !important`,
      width: "100%",
    },
  },
  menuRoot: {
    paddingBottom: "0px",
    paddingTop: "16px",
    fontSize: "16px",
    lineHeight: "28px",
    "@media screen and (max-device-width:599px)": {
      paddingTop: "0px",
      "&:last-child": {
        paddingBottom: "16px",
      },
    },
    "& .languageName": {
      fontSize: "12px",
      color: colors.border,
      lineHeight: "20px",
    },
  },
}));
export default useStyles;
