import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useThemeCustom from "../../hooks/useThemeCustom";
import AboutYouApiForm from "../AboutYou/AboutYouApiForm";
import VerifyDetails from "../VerifyDetails";
import FormSuccess from "./FormSuccess";
import ExistingMember from "./ExistingMember";
import SchoolInfo from "../SchoolInfo";
import { registerWithApiSchool } from "../../services/api";
import { useDispatch } from "react-redux";
import { setFormData, resetFormData } from "../../Redux/action/formDataActions";
import useStyles from "../FormSection/FormSectionStyles";

const ApiSection = () => {
  const { t } = useTranslation();
  const schoolData = useSelector((state) => state?.childDetails?.schoolData);
  const { desktopMatch, mobileMatch } = useThemeCustom();
  const [isError, setIsError] = useState(false);
  const [step, setStep] = useState(1);
  const [member, setMember] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();

  const hide =
    (!desktopMatch && isError) ||
    (!desktopMatch && (step === 2 || step === 3 || step === 4));
    const mobileVerify = mobileMatch && (step === 2 || step === 3 || step === 4);

  useEffect(() => {
    setStep(1);
    setIsError(false);
  }, []);

  const submitForm = (formData) => {
    setMember({name:formData.firstName, email: formData.email});
    dispatch(setFormData(formData));
    const { terms, email, ...rest } = formData;
    const data = {
      schoolId: schoolData.id,
      parentData: {
        ...rest,
        emailAddress: email,
      },
    };
    setStep(2);
    registerWithApiSchool(data)
      .then((response) => {
        dispatch(resetFormData());
        if (response.status === 200) {
          setStep(3);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.isUserAlreadyExist) {
          setStep(4);
        } else {
          setStep(1);
          setIsError(true);
        }
      });
  };

  return (
    <div
      className={desktopMatch ? classes.desktopApiSection : classes.apiSection}
    >
      <SchoolInfo
        hide={hide}
        schoolData={schoolData}
        mobileVerify={mobileVerify}
      />
      {step === 1 ? (
        <AboutYouApiForm isError={isError} submitForm={submitForm} />
      ) : step === 2 ? (
        <VerifyDetails text={t("We are verifying your details")} />
      ) : step === 3 ? (
        <FormSuccess name={member.name} email={member.email} supportEmail={schoolData?.formData?.email} />
      ) : step === 4 ? (
        <ExistingMember name={member.name} supportEmail={schoolData?.formData?.email} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default ApiSection;
