import { useTranslation, Trans } from "react-i18next";
import useThemeCustom from "../../../hooks/useThemeCustom";
import SuccessScreen from "../SuccessScreen";

const FormSuccess = ({ name, email, supportEmail }) => {
  const { t } = useTranslation();
  const { mobileMatch } = useThemeCustom();
  return (
    <SuccessScreen
      heading={t("Welcome firstName!", {firstName: name})}
      description1={
        mobileMatch ? (
          <span>{t("Next step...")}</span>
        ) : (
          <>
            <span>{t("Your registration has been approved.")}{" "} </span>
            {t("We have sent a confirmation email to memberEmail.", {
              memberEmail: email,
            })}
          </>
        )
      }
      description2={
        mobileMatch
          ? t("Download the My Family Hand app.")
          : t(
              "Download the My Family Hand app to access your calendar and account. Once installed, simply open the app and tap the Activate My Account button."
            )
      }
      instruction={
        mobileMatch ? (
          <>
            {t(
              "If you have not received your registration confirmation email after a short while, check your spam folder and Promotions tab (Gmail users) and please mark it as ‘not spam’ or drag it to your inbox to ensure you don’t miss future important emails from us."
            )}
            <br /> <br />
              <Trans
                i18nKey="For assistance, email supportEmail."
                values={{ supportEmail: supportEmail }}
                components={{
                  a: (
                    <a
                      target="_blank"
                      href={`mailto:${supportEmail}`}
                      rel="noreferrer"
                    >
                      {supportEmail}
                    </a>
                  ),
                }}
              />
          </>
        ) : (
          <>
            {t(
              "If you have not received your registration confirmation email after a short while, check your spam folder and Promotions tab (Gmail users) and please mark it as ‘not spam’ or drag it to your inbox to ensure you don’t miss future important emails from us."
            )} <Trans
              i18nKey="For assistance, email supportEmail."
              values={{ supportEmail: supportEmail }}
              components={{
                a: (
                  <a
                    target="_blank"
                    href={`mailto:${supportEmail}`}
                    rel="noreferrer"
                  >
                    {supportEmail}
                  </a>
                ),
              }}
            />
          </>
        )
      }
    />
  );
};

export default FormSuccess;
