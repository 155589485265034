const initialState={
    schoolData: {},
    language: {
        code: "en"
    }
}

const childDetails = (state= initialState, action) =>{
    switch(action.type){
        case 'SET_SCHOOL_DATA' : return {...state, schoolData: action.payload}
        case 'SET_LANGUAGE': return {...state, language: action.language}
        default: return state
        case 'SET_HELP_SECTION': return { ...state, anchorEl: action.anchorEl }
    }
}
export default childDetails