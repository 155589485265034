import React from "react";
import { useState } from "react";
import ImageSkeletonLoader from "../ImageSkeletonLoader/ImageSkeletonLoader";

const LazyLoadImage = ({ src, alt, className, style = {}, loadingStyle, loadingClass, onError = () => {}, onClick = () => {} }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className="lazyLoadContainer">
      {imageLoaded ? null : <ImageSkeletonLoader loadingStyle={loadingStyle} loadingClass={loadingClass} />}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        onClick={onClick}
        className={className}
        style={
          imageLoaded ? style : { visibility: "hidden", height: 1, width: 1 }
        }
        onError={onError}
        loading="lazy"
      />
    </div>
  );
};

export default LazyLoadImage;
