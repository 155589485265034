import { useSelector } from "react-redux";
import clsx from "clsx";
import { MenuItem } from "@material-ui/core";
import { Select, FormControl, InputLabel } from "@mui/material";
import CheckBox from "./Checkbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@material-ui/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { colors } from "../../utils/constant";

const useStyles = makeStyles(() => ({
  select: {
    width: "100%",
    height: "48px",
    textAlign: "start",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${colors.border} !important`,
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "12px 32px 12px 12px",
    },
  },
  rtlSelect: {
    width: "100%",
    height: "48px",
    textAlign: "start",
    padding: "12px 0",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${colors.border} !important`,
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      padding: "12px 32px 12px 12px",
    },
    "& legend": {
      textAlign: "end",
    },
    "& svg": {
      right: "auto !important",
      left: "7px !important",
    },
  },
  selectLabel: {
    transform: "translate(14px, 16px) scale(1)",
    fontSize: "16px",
    fontWeight: "500 !important",
    lineHeight: "18px !important",
    color: `${colors.border3} !important`,
    "@media (max-width:460px)": {
      fontSize: "14px !important",
      transform: "translate(15px, -5px) scale(0.85)",
    },
  },
  rtlSelectLabel: {
    transform: "translate(-14px, 16px) scale(1) !important",
    left: "auto !important",
    right: "0",
    transformOrigin: "top right !important",
  },
  error: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: 1.67,
    color: colors.tertiaryRed,
    textAlign: "start",
    margin: "4px 0 0",
  },
  selectInput:{
    fontSize: "16px",
      "@media (max-width:460px)": {
        fontSize: "14px !important",
      }
  },
  smallCheckbox:{
    "& svg":{
      height: "20px !important"
    }
  },
  noneItem: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
  },
  noneCheckbox: {
    display: "inline-flex",
    alignItems: "center",
  },
  none: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    width: "100%",
  },
  shrink: {
    color: `${colors.typography1} !important`,
  },
  rtlShrink: {
    color: `${colors.typography1} !important`,
    transform: "translate(-14px, -6px) scale(0.75) !important",
    left: "auto !important",
    right: "0",
    transformOrigin: "top right !important",
  },
  focused: {
    color: `${colors.primary} !important`,
  },
}));

const MultiSelectContainer = ({
  startAdornment,
  onChange,
  value,
  label,
  error,
  id,
  labelId,
  name,
  helperText,
  handleBlur,
  required,
  array,
}) => {
  const classes = useStyles();
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const isRTL = currentLanguage?.isRTL;

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel
        required={required}
        variant="outlined"
        id={labelId}
        className={clsx(classes.selectLabel, {
          [classes.rtlSelectLabel]: isRTL,
        }, "responsive-lineHeight")}
        classes={{
          shrink: isRTL ? classes.rtlShrink : classes.shrink,
          focused: classes.focused,
        }}
      >
        {label}
      </InputLabel>
      <Select
        multiple
        variant="outlined"
        labelId={labelId}
        id={id}
        value={value}
        onChange={(e) => {
          let arr = [...e.target.value];
          const noneIndex = arr.findIndex((x) => x === "none");
          if (arr.includes("none")) {
            if (arr.length === 1) {
              arr = ["none"];
            } else {
              if (noneIndex === 0) {
                arr = arr.filter((x) => x !== "none");
              } else {
                arr = ["none"];
              }
            }
          }
          onChange(e, arr);
        }}
        className={isRTL ? classes.rtlSelect : classes.select}
        onBlur={handleBlur}
        error={error}
        name={name}
        label={label}
        IconComponent={KeyboardArrowDownIcon}
        startAdornment={startAdornment}
        inputProps={{ className: classes.selectInput }}
        renderValue={(selected) => {
          if (selected.length === 1 && selected.includes("none")) {
            return "None of these";
          } else if (!!selected && selected.length > 0) {
            let values = array
              .filter((x) => selected.includes(x.value))
              .map((x) => x.key);
            return values.join(", ");
          }
        }}
      >
        <MenuItem key="none" value="none" className={classes.noneItem}>
          <div className={classes.noneCheckbox}>
            <CheckBox
              customClass={classes.smallCheckbox}
              checked={value.includes("none")}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
            />
            <em>None of these</em>
          </div>
          <hr className={classes.none} />
        </MenuItem>
        {!!array &&
          array.length &&
          array.map(function (object) {
            return (
              <MenuItem key={object?.value} value={object?.value}>
                <CheckBox
                  customClass={classes.smallCheckbox}
                  checked={value?.includes(object?.value)}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                />
                {object?.label || object?.key}
              </MenuItem>
            );
          })}
      </Select>
      {Boolean(error) ? (
        <span style={{ height: 20 }} className={classes.error}>
          {helperText}
        </span>
      ) : (
        ""
      )}
    </FormControl>
  );
};

export default MultiSelectContainer;
