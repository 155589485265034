import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useThemeCustom = () => {
    const theme = useTheme();
    const mobileMatch = useMediaQuery(theme.breakpoints.down('xs'));
    const tabletMatch = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const desktopMatch = useMediaQuery(theme.breakpoints.up("lg"));
    return {
        mobileMatch,
        tabletMatch,
        desktopMatch
    }
};

export default useThemeCustom;