export const setFormData = (data) =>{
    return{
        type: 'SET_FORM_DATA',
        payload: data
    }
}

export const resetFormData = () =>{
    return{
        type: 'RESET_FORM_DATA'
    }
}


export const setLoading = (loading) =>{
    return{
        type: 'SET_LOADING',
        loading
    }
}