import "./App.css";
import { useEffect, useMemo } from "react";
import { withFirebase } from "./utils/firebase";
import { mainUser, colors } from "./utils/constant";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import PageNotFound from "./Components/SchoolForm/PageNotFound/PageNotFound";
import { preserveSchoolData } from "./Redux/action/familyActions";
import FormSection from "./Components/FormSection";
import MaintenanceScreen from "./Components/MaintenaceScreen";
import { findSchoolByUrl, getMaintenanceMode } from "./services/api";
import { setLoading } from "./Redux/action/formDataActions";
import "./custom.css"
import { setMaintenanceMode } from "./Redux/action/maintenanceActions";

const App = ({ firebase }) => {
  const dispatch = useDispatch();
  const schoolData = useSelector((state) => state?.childDetails?.schoolData);
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const maintenanceMode = useSelector((state) => state?.maintenance?.mode);
  const getURL = window.location.pathname;
  const formUrl = getURL.slice(1, getURL.length);
  let maintenanceTimer;

  const theme = useMemo(
    () =>
      createTheme({
        typography:{
          fontFamily: currentLanguage.code === "pa" ? "Noto-sans-gurmukhi !important" : currentLanguage.code === "si" ? "Noto-sans-sinhala !important" : "Noto-sans !important",
          color: colors.black
      }
      }),
    [currentLanguage],
  );

  const fetchMaintenanceMode = async () => {
    const res = await  getMaintenanceMode();
    dispatch(setMaintenanceMode(res.mode));
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    maintenanceTimer = setInterval(() => {
      fetchMaintenanceMode();
    }, [5000]);

    setTimeout(() => {
      fetchMaintenanceMode();
    },1000);
    return () => clearInterval(maintenanceTimer);
  },[])

  useEffect(() => {
    firebase
      .doSignInWithEmailAndPassword(mainUser.email, mainUser.password)
      .then((res) => {
        findSchoolByUrl(formUrl)
          .then(function (schoolData) {
            dispatch(preserveSchoolData(schoolData.data));
            dispatch(setLoading(false));
          })
          .catch(function (error) {
            console.log("Error getting documents: ", error);
            dispatch(setLoading(false));
          });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formUrl]);

  return (
    <ThemeProvider theme={theme}>
      <div className={`App ${currentLanguage?.isRTL ? "rtlResponsive" : ""} ${currentLanguage.code}-language`}>
        <Router>
          <Routes>
            <Route path="/" element={<PageNotFound />} />
            <Route
              path={`/${!schoolData ? schoolData.formData.urlPath : formUrl}`}
              element={!!formUrl ? (maintenanceMode? <MaintenanceScreen/> : <FormSection />) : <PageNotFound />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default withFirebase(App);
