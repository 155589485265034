import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../utils/constant";
const useStyles = makeStyles(() => ({
    maintenanceScreen:{
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      padding: "40px 70px 0 70px",
      minHeight: "580px",
      backgroundColor: colors.white,
      borderRadius: "20px",
      maxWidth: "480px",
      width: "calc(100vw - 200px)",
      boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.1)",
      "@media screen and (max-width:1279px)": {
        minHeight: "fit-content"
      },
      "@media screen and (max-width:599px)": {
        width: "calc(100vw - 80px)",
        padding: "20px 20px 0 20px",
        minHeight: "fit-content"
      },

    },
    skeletonSquare:{
        borderRadius:"16px !important",
        marginBottom: "20px"
    },
    skeletonTypography:{
      borderRadius: "4px !important",
      marginBottom: "20px",
      "&:last-child":{
        marginBottom: "202px !important"
      }
    },
    title:{
      fontSize: "28px",
      lineHeight: "40px",
      fontWeight: "bold",
      "@media screen and (max-width:599px)": {
        fontSize: "24px",
      }
    },
    h1:{
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 600,
      marginBottom: "20px",
      width: "100%"
    },
    h2:{
      fontSize: "16px",
      lineHeight: "28px",
      marginBottom: "20px",
      "@media screen and (max-width:599px)": {
        fontSize: "14px",
      }
    },
    h3:{
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "20px",
      fontWeight: 600,
      "@media screen and (max-width:599px)": {
        fontSize: "16px",
      }
    },
    h4:{
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: 600,
      marginBottom: "210px",
      color: colors.border3,
      "@media screen and (max-width:599px)": {
        fontSize: "16px",
      }
    },
    loadingImageContainer:{
      "& svg":{
        height: "160px",
        width: "160px",
        borderRadius: "16px"
      }
    },
    imageContainer:{
      height: "160px",
      width: "160px",
      margin: "20px auto"
    },
    emailField:{
      width: "100%",
      marginBottom: "24px",
      "& label":{
        color: colors.dark
      },
      "& ::placeholder" : {
        color: colors.dark,
        opacity: 1
      },
      "@media screen and (max-width:599px)": {
        marginBottom: "32px",
      }
    },
    textField:{
      height: "60px",
      paddingRight: "0px",
      fontSize: "14px",
      background: `${colors.primaryExtraLightGrey} !important`,
      borderRadius: "100px",
      "& fieldset":{
        borderColor: `${colors.primaryLightGrey} !important`,
        borderRadius: "100px"
      },
      "@media screen and (max-width:599px)": {
        height: "44px"
      }
    },
    rtlTextField:{
      paddingRight: "14px !important"
    },
    notifyButton:{
      boxShadow: "none !important",
      height: "52px",
      borderRadius:"100px",
      margin: "4px 4px 4px auto",
      minWidth: "135px",
      maxWidth: "fit-content",
      background: `${colors.primary} !important`,
      color: colors.white,
      fontSize: "16px",
      fontWeight: "bold",
      lineHeight: 1.75,
      textTransform: "none",
      "@media screen and (max-width:599px)": {
        width: "100%",
        marginTop: "8px",
        height: "44px",
        fontSize: "14px",
        maxWidth: "100%",
      }
    },
    checkmarkButton:{
      boxShadow: "none !important",
      borderRadius:"100px",
      minWidth: "56px",
      width: "56px",
      height: "56px",
      background: `${colors.primary} !important`,
      "@media screen and (max-width:599px)": {
        minWidth: "36px",
      width: "36px",
      height: "36px",
      },
      marginRight: "4px",
      "& img":{
        height: "32px",
        width: "32px",
        "@media screen and (max-width:599px)": {
          width: "24px",
          height: "24px",
        },
      }
    },
    rtlInformation:{
      "& img":{
        left: "auto",
        right: "-24px"
      },
    },
    information:{
      position: "relative",
      "& img":{
        position: "absolute",
        height: "22px",
        width: "22px",
        left: "-24px"
      },
      "& p":{
        marginTop: "15px",
        textAlign: "left",
        fontSize: "12px",
        lineHeight: "20px",
        color: colors.border3
      },
      "@media screen and (max-width:1279px)": {
        marginBottom: "61px"
      },
      "@media screen and (max-width:599px)": {
        margin: "0 0 20px 20px"
      }
    },
    emailSentImg:{
      width: "100px",
      height: "100px",
      marginBottom: "32px",
      marginTop: "170px"
    }
}))

export default useStyles;