import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import i18n from "i18next";
import * as Yup from "yup";
import { useTranslation, Trans } from "react-i18next";
import { Typography, Grid, FormControl } from "@material-ui/core";
import useThemeCustom from "../../../hooks/useThemeCustom";
import TextField from "../../CustomizedMui/TextField";
import CheckBox from "../../CustomizedMui/Checkbox";
import Button from "../../CustomizedMui/Button";
import TermsAndConditions from "../TermsAndConditions";
import {
  apiSchoolFormSchema,
} from "../../../utils/constant";
import { setHelpSection } from "../../../Redux/action/familyActions";
import { resetFormData } from "../../../Redux/action/formDataActions";
import useStyles from "./AboutYouApiFormStyles";

const AboutYouApiForm = ({ isError, submitForm }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { desktopMatch } = useThemeCustom();
  const [initialValues, setInitialValues] = useState(apiSchoolFormSchema);
  const formData = useSelector((state) => state.formData.formData);

  useEffect(() => {
    if(!!formData && Object.values(formData).length > 0){
      setInitialValues(formData);
    }
  },[formData]);

  const languageChangeHandler = useCallback(() => {
    dispatch(resetFormData());
    setInitialValues(apiSchoolFormSchema);
  },[dispatch]);

  useEffect(() => {
    i18n.on("languageChanged", languageChangeHandler)
  },[languageChangeHandler]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required(t("Required")),
    lastName: Yup.string().trim().required(t("Required")),
    email: Yup.string()
      .trim()
      .email(t("Invalid email address"))
      .required(t("Required")),
    phoneNo: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .trim()
      .min(9, t("Number must be a min. of 9 digits"))
      .max(11)
      .required(t("Required")),
    terms: Yup.boolean().oneOf([true], t("Required"))
  });

  const openHelpSection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setHelpSection(e.currentTarget))
  };

  return (
    <div className={desktopMatch ? classes.desktopAboutYouFormRoot : classes.aboutYouFormRoot}>
      <Typography className={classes.heading}>{t("About you")}</Typography>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          submitForm(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label={t("First name")}
                    name="firstName"
                    value={values.firstName}
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    label={t("Last name")}
                    name="lastName"
                    value={values.lastName}
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label={t("Email address")}
                    name="email"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    error={Boolean(touched.email && errors.email) || isError}
                    helperText={
                      isError ? (
                        <>
                          {t("Your email address does not match a record held by your school")}
                          <br /> <span className="helpGuide" onClick={openHelpSection}>({t("click here to see our help guide")})</span>
                        </>
                      ) : (
                        t("This must be the same as what is registered with the Dept. of Education")
                      )
                    }
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label={t("Phone number")}
                    name="phoneNo"
                    value={values.phoneNo}
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    error={Boolean(touched.phoneNo && errors.phoneNo)}
                    helperText={touched.phoneNo && errors.phoneNo}
                  />
                  <span className={classes.space}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className={classes.heading}>
                  {t("Protecting your privacy")}
                  </Typography>
                  <TermsAndConditions />
                </Grid>
                <CheckBox
                  checked={values.terms}
                  handleTermCheck={() => setFieldValue("terms", !values.terms)}
                  error={touched.terms && errors.terms}
                  condition={
                      <Trans i18nKey="I agree to the terms and conditions"
                        values={{ privacy: t("Privacy Policy"), terms: t("Terms & Conditions")}}
                        components={{
                          a1: <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BASE_URL}/privacy-policy`}>{t("Privacy Policy")}</a>,
                          a2:  <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_BASE_URL}/school-terms-and-conditions`}>{t("Terms & Conditions")}</a>
                          }}/>
                  }
                />
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.submitButton}>
                  <FormControl fullWidth>
                    <Button onClick={handleSubmit} >{t("Register")}</Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AboutYouApiForm;
