import MaintenanceForm from "./MaintenanceForm";
import DashboardHeader from "../DashboardHeader";
import FooterLinks from "../FooterLinks";
import useThemeCustom from "../../hooks/useThemeCustom";
import useStyles from "./MaintenanceScreenStyles";

const MaintenanceScreen = () => {
    const classes = useStyles();
    const { mobileMatch } = useThemeCustom();
    return (
        <div className={classes.desktopContainer}>
            <DashboardHeader/>
            <div className={classes.desktopFormRoot}>
                <MaintenanceForm/>
                {!mobileMatch && <FooterLinks/>}
            </div>
        </div>
    )
}

export default MaintenanceScreen;