import childDetails from "./familyReducer";
import formData from './formReducer';
import maintenanceMode from "./maintenanceReducer";
import {combineReducers} from 'redux';
const rootReducer = combineReducers({
    childDetails: childDetails,
    formData: formData,
    maintenance: maintenanceMode
})

export default rootReducer