import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import clsx from "clsx";
import useThemeCustom from "../../hooks/useThemeCustom";
import LazyLoadImage from "../LazyLoadImage/LazyLoadImage";
import { convertToRgba } from "../../utils/constant";
import useStyles from "./SchoolInfoStyles";

const SchoolInfo = ({ schoolData, mobileVerify, hide }) => {
  const { t } = useTranslation();
  const { desktopMatch, mobileMatch } = useThemeCustom();
  const classes = useStyles();
  return (
    <div
      style={{backgroundColor: `${convertToRgba(schoolData?.formData?.schoolColor, 0.1)}`}}
      className={clsx(classes.fonts, desktopMatch
        ? classes.desktopSchoolInfo
        : mobileVerify
        ? classes.hide
        : classes.schoolInfo)
      }
    >
      <LazyLoadImage
        loadingClass={
          !mobileMatch ? classes.desktopImageContainer : classes.imageContainer
        }
        className={
          !mobileMatch ? classes.desktopImageContainer : classes.imageContainer
        }
        src={!!schoolData && schoolData.formData.schoolLogo.url}
        alt="school_logo"
      />
      <Typography className="schoolInfo-1">{schoolData.schoolName}</Typography>
      <Typography className="schoolInfo-2">
        School Calendar Organiser
      </Typography>
      {!hide && (
        <>
          {" "}
          <Typography className="schoolInfo-3">
            {t("Access your personalised diary of schoolName events and parent tasks securely in the My Family Hand School Calendar Organiser.", {schoolName: schoolData.formData.schoolShortName})}
          </Typography>
          <Typography className="schoolInfo-4">
           {t("Available on iOS & Android")}
          </Typography>
        </>
      )}
    </div>
  );
};

export default SchoolInfo;
