import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MywhanauLogo from "../../../assets/my family hand Logo@3x.png";
import PageNotFoundImage from "../../../assets/mywhanau-404bg.jpg";
import { Typography } from "@material-ui/core";
import LazyLoadImage from "../../LazyLoadImage/LazyLoadImage";
import useThemeCustom from "../../../hooks/useThemeCustom";
import { colors, adminEmail } from "../../../utils/constant";

const useStyles = makeStyles((theme) => ({
  myWhanauLogo: {
    width: "219.7px;",
    height: "120px",
  },
  content: {
    position: "absolute",
    textAlign: "center",
    // margin: '15px auto auto auto',
    top: "50%",
    msTransform: "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  desktopContent: {
    position: "absolute",
    textAlign: "center",
    // margin: ' auto auto auto',
    justifyContent: "center",
    alignItems: "center",
    display: "block",
    width: "100%",
    top: "50%",
    msTransform: "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  tabletContent: {
    position: "absolute",
    textAlign: "center",
    // margin: '300px auto auto auto',
    justifyContent: "center",
    alignItems: "center",
    display: "block",
    width: "100%",
    top: "50%",
    msTransform: "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  mobileContent: {
    position: "absolute",
    textAlign: "center",
    // margin: '160px auto auto auto',
    justifyContent: "center",
    alignItems: "center",
    display: "block",
    width: "100%",
    top: "50%",
    msTransform: "translateY(-50%)",
    transform: "translateY(-50%)",
  },
  mobileMywhanauLogo: {
    margin: "0 0 20px 0",
    width: "219.7px;",
    height: "120px",
  },
  tabletMywhanauLogo: {
    margin: "0 25px 30px 0",
    width: "219.7px;",
    height: "120px",
    // position: 'absolute'
  },
  mobileNotFoundText: {
    fontSize: "20px",
    fontWeight: "bold",
    color: colors.white,
    // margin: "0 25px 15px 0",
    fontFamily: "Montserrat",
  },
  mobileUrlNotFoundText: {
    fontSize: "16px",
    fontWeight: "normal",
    color: colors.white,
    margin: "20px auto 0px auto;",
    fontFamily: "Open Sans",
    width: "325px",
    textAlign: "center",
    justifyContent: "center",
  },
  tabletNotFoundText: {
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: colors.white,
    margin: "0 25px 30px 0",
  },
  tabletUrlNotFoundText: {
    fontSize: "16px",
    fontWeight: "normal",
    color: colors.white,
    margin: "0 auto 30px auto",
    fontFamily: "Open Sans",
    width: "600px",
    textAlign: "center",
    justifyContent: "center",
  },
  pageNotFoundText: {
    fontSize: "24px",
    fontWeight: "bold",
    color: colors.white,
    margin: "20px 0 0 0",
    fontFamily: "Montserrat",
  },
  urlNotFoundText: {
    fontSize: "16px",
    fontWeight: "normal",
    color: colors.white,
    margin: "20px auto 35px auto",
    fontFamily: "Open Sans",
    width: "600px",
    textAlign: "center",
    justifyContent: "center",
  },
  Buttons: {
    border: `2px solid ${colors.primary}`,
    height: "38px",
    width: "212px",
    color: colors.white,
    textDecoration: "none",
    padding: "10px 0px 0px 0px",
    margin: "10px auto 0 auto",
    borderRadius: "3px",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "bold",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  tabletButtons: {
    border: `2px solid ${colors.primary}`,
    height: "38px",
    width: "212px",
    color: colors.white,
    textDecoration: "none",
    padding: "10px 0px 0px 0px",
    margin: "10px auto 0 auto",
    borderRadius: "3px",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "bold",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  mobileButtons: {
    border: `2px solid ${colors.primary}`,
    height: "38px",
    width: "212px",
    color: colors.white,
    textDecoration: "none",
    padding: "10px 0 0 0",
    margin: "10px auto 0 auto",
    borderRadius: "3px",
    fontFamily: "Open Sans",
    fontSize: "16px",
    fontWeight: "bold",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    "@media (min-width:100px) and (max-width: 392px)": {
      textTransform: "none",
      border: `2px solid ${colors.primary}`,
      height: "38px",
      width: "250px",
      color: colors.white,
      textDecoration: "none",
      padding: "10px 0 0 0",
      margin: "10px auto 0 auto",
      borderRadius: "3px",
      fontFamily: "Open Sans",
      fontSize: "16px",
      fontWeight: "bold",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  },
  pageNotFoundImage: {
    // position: "absolute",
    overflow: "hidden",
    maxHeight: "100%",
    width: "100%",
    height: "100vh",
    objectFit: "cover",
  },
  pageNotFoundImageLoading: {
    // position: "absolute",
    overflow: "hidden",
    maxHeight: "100%",
    width: "100%",
    height: "100vh",
    objectFit: "cover",
  },
  mobilePageNotFoundImage: {
    // position: "absolute",
    overflow: "hidden",
    height: "100vh",
    width: "100%",
    right: 0,
    objectFit: "cover",
  },
  mobilePageNotFoundImageLoading: {
    // position: "absolute",
    overflow: "hidden",
    height: "100vh",
    width: "100%",
    right: 0,
    objectFit: "cover",
  },
  tabletPageNotFoundImage: {
    // position: "absolute",
    overflow: "hidden",
    height: "100vh",
    width: "100%",
    right: 0,
    objectFit: "cover",
  },
  tabletPageNotFoundImageLoading: {
    // position: "absolute",
    overflow: "hidden",
    height: "100vh",
    width: "100%",
    right: 0,
    objectFit: "cover",
  },
  desktopPageNotFoundImage: {
    // position: "absolute",
    overflow: "hidden",
    maxHeight: "100%",
    width: "100%",
    height: "100vh",
    objectFit: "cover",
  },
  desktopPageNotFoundImageLoading: {
    // position: "absolute",
    overflow: "hidden",
    maxHeight: "100%",
    width: "100%",
    height: "100vh",
    objectFit: "cover",
  },
}));

const PageNotFound = ({ firebase }) => {
  const classes = useStyles();
  const {tabletMatch, desktopMatch, mobileMatch} = useThemeCustom();

  return (
    <div>
      <div>
        <LazyLoadImage
          src={PageNotFoundImage}
          alt="error"
          className={
            desktopMatch
              ? classes.desktopPageNotFoundImage
              : tabletMatch
              ? classes.tabletPageNotFoundImage
              : mobileMatch
              ? classes.mobilePageNotFoundImage
              : classes.pageNotFoundImage
          }
          loadingClass={
            desktopMatch
              ? classes.desktopPageNotFoundImageLoading
              : tabletMatch
              ? classes.tabletPageNotFoundImageLoading
              : mobileMatch
              ? classes.mobilePageNotFoundImageLoading
              : classes.pageNotFoundImageLoading
          }
          loadingStyle={{ width: "100%", height: "100%" }}
        />
      </div>
      <div
        className={
          desktopMatch
            ? classes.desktopContent
            : tabletMatch
            ? classes.tabletContent
            : mobileMatch
            ? classes.mobileContent
            : classes.content
        }
      >
        <LazyLoadImage
          src={MywhanauLogo}
          alt="error"
          className={
            tabletMatch
              ? classes.tabletMywhanauLogo
              : mobileMatch
              ? classes.mobileMywhanauLogo
              : classes.myWhanauLogo
          }
          loadingStyle={{ width: 160, height: 88 }}
        />
        <Typography
          className={
            tabletMatch
              ? classes.tabletNotFoundText
              : mobileMatch
              ? classes.mobileNotFoundText
              : classes.pageNotFoundText
          }
        >
          Page cannot be found
        </Typography>
        <Typography
          className={
            tabletMatch
              ? classes.tabletUrlNotFoundText
              : mobileMatch
              ? classes.mobileUrlNotFoundText
              : classes.urlNotFoundText
          }
        >
          Either the URL is incorrect or the page has been moved. If you need
          assistance please email us -{" "}
          <a
            style={{ color: colors.primary, textDecoration: "none" }}
            href={`mailto:${adminEmail}`}
          >
            {adminEmail}
          </a>{" "}
          or use the button below.
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: tabletMatch ? "row" : mobileMatch ? "column" : "row",
            margin: "15px auto auto auto",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <a
            href={`mailto:${adminEmail}`}
            className={
              tabletMatch
                ? classes.tabletButtons
                : mobileMatch
                ? classes.mobileButtons
                : classes.Buttons
            }
            target="_blank"
            rel="noreferrer noopener"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
