import { createTheme } from "@material-ui/core";

const encryptedPasswordSecret = "mywhanau__secret__key";

const mainUser = {
  email: "test.register@mywhanau.com",
  password: "TestRegister@41",
};

const copyFileUrl = "https://mywhanau-admin.web.app/";
const colors = {
  primary: "#8bc517",
  primaryDark: "#474747",
  primaryDarkGrey: "#4c5b68",
  primaryDarkGrey7: "rgba(76, 91, 104, 0.7)",
  primaryLightGrey: "#bfc5d1",
  primaryExtraLightGrey: "#EDEDED",
  secondaryGreyLight: "#f4f5f7",
  tertiary: "#F4F6F8",
  tertiaryText: "#F1F1F1",
  tertiaryRed: "#bd0000",
  tertiaryDeleteRed: "#f60611",
  tertiaryConfirm: "#FF8A00",
  error: "#C40F0F",
  white: "#FFFFFF",
  rootBackground: "#fff9f5",
  background:"#fdfbf9",
  dark: "#1d1d1d",
  border:"#c6c6c6",
  border2:"#d3d3d3",
  typography1: "#848484",
  dark2: "#aaaaaa",
  border3: "#6c6c6c",

};

const userColors = ["#ff5200", "#2295a1", "#a81ae9", "#ff9b00", "#164cfe"];

const schoolYearsColors = [
  "#20AE03",
  "#ff5200",
  "#2295a1",
  "#a81ae9",
  "#ff9b00",
  "#164cfe",
  "#f90035",
  "#820ea8",
  "#ffcb00",
  "#aa8049",
  "#0090d1",
  "#a9144a",
  "#ecd801",
  "#34DAD4",
  "#EBD62F",
];

const schoolFileType = {
  LOGO: "logo",
  SUPPORT_PERSON: "supportPerson",
};

const routes = {
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  codeVerify: "/code-verify",
  pageNotFound: "/page-not-found",
  help: "https://drive.google.com/file/d/1RrbZlZTT917bIGXNsEOiq86iLNgcLvTi/view",
  families: "/families",
  agents: "/agents",
  agentCreate: "/agents/create",
  groups: "/groups",
  groupCreate: "/groups/create",
  schools: "/schools",
  schoolCreate: "/schools/create",
  schoolCalendar: "/schools/calendar",
  schoolProfile: "/schools/profile",
  schoolLinks: "/schools/links",
  schoolForm: "/schools/form",
  familyCalendar: "/families/calendar",
  familyFiles: "/families/files",
  familyNotes: "/families/notes",
  familyLinks: "/families/links",
  familyUsers: "/families/users",
  familyAccount: "/families/account",
  familyCreate: "/families/create",
  appResetPassword: "/app-reset-password",
  appPasswordUpdated: "/app-password-updated",
};

const drawerWidth = 180;

const languageLabels = [
  {
    code:"en",
    label:"English"
  },
  {
    code:"ar",
    label:"العربية"
  },
  {
    code:"bn",
    label:"বাংলা"
  },
  {
    code:"zh",
    label:"廣東話"
  },
  {
    code:"fil",
    label:"Filipino"
  },
  {
    code:"fr",
    label:"Français"
  },
  {
    code:"de",
    label:"Deutsch"
  },
  {
    code:"el",
    label:"Ελληνικά"
  },
  {
    code:"gu",
    label:"ગુજરાતી"
  },
  {
    code:"hi",
    label:"हिन्दी"
  },
  {
    code:"id",
    label:"Bahasa Indonesia"
  },
  {
    code:"it",
    label:"Italiano"
  },
  {
    code:"ko",
    label:"한국어"
  },
  {
    code:"ml",
    label:"മലയാളം"
  },
  {
    code:"yue",
    label:"普通话"
  },
  {
    code:"ne",
    label:"नेपाली"
  },
  {
    code:"fa",
    label:"فارسی"
  },
  {
    code:"pt",
    label:"Português"
  },
  {
    code:"pa",
    label:"ਪੰਜਾਬੀ"
  },
  {
    code:"si",
    label:"සිංහල"
  },
  {
    code:"es",
    label:"Español"
  },
  {
    code:"ta",
    label:"தமிழ்"
  },
  {
    code:"ur",
    label:"اردو"
  },
  {
    code:"vi",
    label:"Tiếng Việt"
  },
  {
    code: "mn",
    label: "Монгол"
  }
];

const countries = [
  {
    key: 'Australia',
    value: 'Australia'
  },
  {
    key: 'New Zealand',
    value: 'New Zealand'
  },
  {
    key: 'Hong Kong',
    value: 'Hong Kong'
  }
];

const states = [
  {
    country: "AU",
    code: "NSW",
    name: "New South Wales",
  },
  {
    country: "AU",
    code: "VIC",
    name: "Victoria",
  },
  {
    country: "AU",
    code: "QLD",
    name: "Queensland",
  },
  {
    country: "AU",
    code: "WA",
    name: "Western Australia",
  },
  {
    country: "AU",
    code: "SA",
    name: "South Australia",
  },
  {
    country: "AU",
    code: "TAS",
    name: "Tasmania",
  },
  {
    country: "AU",
    code: "ACT",
    name: "Australian Capital Territory",
  },
  {
    country: "AU",
    code: "NT",
    name: "Northern Territory",
  },
  {
    country: "NZ",
    code: "NZ",
    name: "New Zealand",
  },
  {
    country: "UK",
    code: "OH",
    name: "Ohio",
  },
];

// If updated, update in firebase funtions timezones too.
const timeZones = [
  {
    value: 8,
    text: "(UTC +8:00) Australian Western Standard Time (AWST)",
  },
  {
    value: 8.01,
    text: "(UTC +8:00) Hong Kong Time (HKT)",
  },
  {
    value: 8.75,
    text: "(UTC +8:45) Australian Central Western Standard Time (ACWST)",
  },
  {
    value: 9.5,
    text: "(UTC +9:30) Australian Central Standard Time (ACST)",
  },
  {
    value: 10,
    text: "(UTC +10:00) Australian Eastern Standard Time (AEST)",
  },
  {
    value: 10.01,
    text: "Queensland - (UTC +10:00) Australian Eastern Standard Time (AEST)",
  },
  {
    value: 10.5,
    text: "(UTC +10:30) Lord Howe Standard Time (LHST)",
  },
  {
    value: 12,
    text: "(UTC +12:00) New Zealand Standard Time (NZST)",
  },
  {
    value: 12.75,
    text: "(UTC +12:45) Chatham Island Standard Time (CHAST)",
  },
];

const accountTypes = [
  {
    label: "Family Calendar Assistant: 1 Child",
    value: "Paid 1-Child",
  },
  {
    label: "Family Calendar Assistant: 2-3 Child",
    value: "Paid 2-Child",
  },
  {
    label: "Family Calendar Assistant: 4 Child",
    value: "Paid 4-Child",
  },
  { label: "School Calendar Assistant", value: "Free" },
];

const familyChildStatus = [
  "New",
  "Invited",
  "Remind 1",
  "Remind 2",
  "Open",
  "Open Remind",
  "Active",
];

const emailTypes = {
  USER_REGISTERED: {
    type: "USER_REGISTERED",
    customData: {
      firstName: "",
      schoolName: "",
      currentYear: new Date().getFullYear(),
    },
  },
  NEW_USER_TO_ADMIN: {
    type: "NEW_USER_TO_ADMIN",
    customData: {
      schoolName: "",
      accountName: "",
      firstName: "",
      lastName: "",
    },
  },
  MEMBER_ALREADY_EXIST: {
    type: "MEMBER_ALREADY_EXIST",
    customData: {
      schoolName: "",
      accountName: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
    },
  },
};

const adminEmail = "care@myfamilyhand.com.au";
const supportPersonEmail = "michelle@myfamilyhand.com.au";

const hostRadio = [
  {
    label: "No",
    value: "false",
  },
  {
    label: "Yes",
    value: "true",
  },
];

const groupEntryRadio = [
  {
    label: "Families",
    value: "Families",
  },
  {
    label: "Group",
    value: "Group",
  },
  {
    label: "Free",
    value: "Free",
  },
];

const gender = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

const year = [
  {
    label: "Pre K",
    value: "-1",
  },
  {
    label: "Kindy",
    value: "0",
  },
  {
    label: "Year 1",
    value: "1",
  },
  {
    label: "Year 2",
    value: "2",
  },
  {
    label: "Year 3",
    value: "3",
  },
  {
    label: "Year 4",
    value: "4",
  },
  {
    label: "Year 5",
    value: "5",
  },
  {
    label: "Year 6",
    value: "6",
  },
  {
    label: "Year 7",
    value: "7",
  },
  {
    label: "Year 8",
    value: "8",
  },
  {
    label: "Year 9",
    value: "9",
  },
  {
    label: "Year 10",
    value: "10",
  },
  {
    label: "Year 11",
    value: "11",
  },
  {
    label: "Year 12",
    value: "12",
  },
  {
    label: "Year 13",
    value: "13",
  },
];

const role = [
  {
    label: "Agent",
    value: "Agent",
  },
  {
    label: "Manager",
    value: "Manager",
  },
];

const labels = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "Read",
    value: "Read",
  },
  {
    label: "Complete",
    value: "Complete",
  },
  {
    label: "Complete (?)",
    value: "Complete (?)",
  },
  {
    label: "Attend",
    value: "Attend",
  },
  {
    label: "Attend (?)",
    value: "Attend (?)",
  },
  {
    label: "Register",
    value: "Register",
  },
  {
    label: "Register (?)",
    value: "Register (?)",
  },
  {
    label: "Buy",
    value: "Buy",
  },
  {
    label: "Buy (?)",
    value: "Buy (?)",
  },
  {
    label: "Assessment",
    value: "Assessment",
  },
  {
    label: "Pay",
    value: "Pay",
  },
  {
    label: "Special Deal",
    value: "Special Deal",
  },
  {
    label: "Hint",
    value: "Hint",
  },
  {
    label: "School Holiday",
    value: "School Holiday",
  },
];

const taskLabels = [
  "Complete",
  "Complete (?)",
  "Register",
  "Register (?)",
  "Buy",
  "Buy (?)",
  "Pay",
];

const weekNames = [
  {
    label: "M",
    value: "Mon",
    selected: false,
  },
  {
    label: "T",
    value: "Tue",
    selected: false,
  },
  {
    label: "W",
    value: "Wed",
    selected: false,
  },
  {
    label: "T",
    value: "Thu",
    selected: false,
  },
  {
    label: "F",
    value: "Fri",
    selected: false,
  },
  {
    label: "S",
    value: "Sat",
    selected: false,
  },
  {
    label: "S",
    value: "Sun",
    selected: false,
  },
];

const muiTimePickerTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        paddingLeft: 10,
        alignItems: "center",
        display: "flex",
        height: 23,
        fontSize: 14,
      },
    },
    MuiInput: {
      underline: {
        "&:after": {
          borderBottom: "unset !important", //when input is focused, Its just for example. Its better to set this one using primary color
        },
        "&:before": {
          borderBottom: "unset !important", // when input is not touched
        },
      },
    },
  },
});

const apiSchoolFormSchema = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  terms: false
};

const nonApiSchoolFormSchema = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  country: "Australia",
  timezone: 10,
  isVisibleGroupClass: false,
  isVisibleGroupSubject: false,
  isVisibleGroupCorricular: false,
  childs: [
    {
      firstName: "",
      lastName: "",
      year: "",
      groupClass: "",
      groupCorricular: [],
      groupSubject: [],
    },
  ],
  terms: false
};

const convertToRgba = (hashColor, alpha) => {
  hashColor = hashColor?.replace('#', '');

  // Parse the hexadecimal components
  const r = parseInt(hashColor?.substring(0, 2), 16);
  const g = parseInt(hashColor?.substring(2, 4), 16);
  const b = parseInt(hashColor?.substring(4, 6), 16);

  // If the hash color includes an alpha component, use it; otherwise, use the provided alpha
  const a = alpha !== undefined ? alpha : 1;

  // Return the RGBA string
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export {
  colors,
  userColors,
  routes,
  drawerWidth,
  schoolYearsColors,
  schoolFileType,
  languageLabels,
  countries,
  timeZones,
  accountTypes,
  familyChildStatus,
  emailTypes,
  adminEmail,
  supportPersonEmail,
  groupEntryRadio,
  hostRadio,
  gender,
  year,
  role,
  labels,
  weekNames,
  states,
  copyFileUrl,
  taskLabels,
  muiTimePickerTheme,
  encryptedPasswordSecret,
  mainUser,
  apiSchoolFormSchema,
  nonApiSchoolFormSchema,
  convertToRgba
};
