export const preserveSchoolData = (schoolData) =>{
    return{
        type: 'SET_SCHOOL_DATA',
        payload: schoolData
    }
}

export const setLanguage = (language) => {
    return {
        type: 'SET_LANGUAGE',
        language
    }
}

export const setHelpSection = (anchorEl) =>{
    return{
        type: 'SET_HELP_SECTION',
        anchorEl
    }
}