import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../utils/constant";

const useStyles = makeStyles(() => ({
    successScreenRoot: {
        margin: "24px auto",
        maxWidth: "436px",
        "& *": {
          textAlign: "start",
        },
        "@media (max-width:500px)": {
          width: "calc(100vw - 40px)",
        },
        "@media (max-width:450px)": {
          width: "calc(100vw - 40px)",
        },
        "& .lastInstruction":{
          marginTop: "16px"
        },
        "@media (max-width:599px)": {
          position: "initial !important",
        },
        position: "relative",
      },
      desktopSuccessScreenRoot: {
        padding: "32px 84px 0px 116px",
        position: "relative",
        overflowY: "auto",
        "& *": {
          textAlign: "start",
        },
        "& .lastInstruction":{
          marginTop: "16px"
        }
      },
      stepList:{
        display: "flex",
        flexFlow: "column",
        marginTop: "32px",
        marginBottom: "32px"
      },
      step:{
        display: "inline-flex",
        "& img":{
          height: "46px",
          width: "46px",
          marginRight: "16px",
          borderRadius: "50%"
        },
        "&:first-child":{
          marginBottom: "24px"
        }
      },
      rtlStep:{
        "& img":{
          marginRight: "0px !important",
          marginLeft: "16px !important",
        }
      },
      heading: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "24px",
      },
      description:{
        fontSize: "16px",
        lineHeight: 1.75,
        marginBottom: "24px",
        "& a":{
          zIndex: 1,
          position: "sticky"
        }
    },
    heading2:{
      fontSize: "16px",
      lineHeight: 1.75,
      fontWeight: "bold",
  },
  description2:{
    lineHeight: "26px",
    fontSize: "16px",
    fontWeight: 400
  },
  aboutIcon: {
    height: "24px",
    width: "24px",
    marginBottom: "8px",
    display: "block",
  },
  instructions: {
    fontSize: "12px",
    lineHeight: 1.67,
    "& a":{
      textDecoration: "none",
      color: colors.dark,
      zIndex: 1,
      position: "sticky"
    }
  }
}))

export default useStyles;