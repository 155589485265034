import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/constant";
const useStyles = makeStyles(() => ({
  root: {
    display: "inline-flex",
    marginTop: "32px",
    flexWrap: "wrap",
    "@media screen and (min-device-width: 1280px) and (max-device-height: 820px)": {
      marginBottom: "32px",
    },
  },
  linkContainer: {
    display: "inline-flex",
    alignItems: "center",
    "&:nth-child(1),&:nth-child(2)": {
      marginRight: "16px",
    },
    "& a": {
      height: "16px",
      marginRight: "4px",
      display: "inline-flex",
      alignItems: "center",
      textDecoration: "none",
      "@media screen and (max-device-width: 540px)":{
        paddingBottom: "8px !important"
      }
    },
    "& img": {
      height: "16px",
      width: "16px",
      marginRight: "6px"
    },
    "& span": {
      fontSize: "14px",
      fontWeight: "600",
      color: colors.dark2,
      lineHeight: "2",
    },
  },
  rtlLinkContainer:{
    "& span": {
      marginRight: "4px"
    }
  }
}));

export default useStyles;
