import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useThemeCustom from "../../../hooks/useThemeCustom";
import SuccessConfetti from "../../SuccessConfetti";
import useStyles from "./ExistingMemberStyles";

const ExistingMember = ({name, supportEmail}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { desktopMatch } = useThemeCustom();
    return (
        <div className={desktopMatch ? classes.desktopExistingMember: classes.existingMember}>
            <Typography className={classes.heading}>{t("Hi firstName!", {firstName: name})}</Typography>
            <div className={classes.existingContainer}>
                <Typography>{t("We found an existing account for your email address")}</Typography>
            </div>
            <Typography className={classes.description}>
                {t("As a member you get priority. Please contact us using the email address below so we can quickly add your new registration requirements into your existing account.")}
            </Typography>
            <Typography className={classes.heading2}>{t("Email")}</Typography>
            <Typography className={classes.description}><a href={`mailto:${supportEmail}`}>{supportEmail}</a></Typography>
            <SuccessConfetti/>
        </div>
    )
}

export default ExistingMember;