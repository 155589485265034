import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  desktopVerifyScreenRoot: {
    padding: "166px 107px",
    width: "100%",
    "& h6": {
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "24px",
      lineHeight: 1.43
    },
    "& p": {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.75,
     "@media screen and (max-device-width:599px)": {
        fontSize: "14px",
        lineHeight: 2,
      }
    },
  },
  verifyScreenRoot: {
    padding: "100px 0",
    width: "100%",
    "@media (max-width:500px)": {
      width: "calc(100vw - 100px)",
      margin: "auto",
    },
    "& h6": {
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "24px",
      lineHeight: 1.43,
      "@media screen and (max-device-width:430px)": {
        fontSize: "24px",
        lineHeight: 1.33,
      }
    },
    "& p": {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.75,
      "@media screen and (max-device-width:430px)": {
        fontSize: "14px",
        lineHeight: 2,
      }
    },
  },
  loadingIcon: {
    height: "200px",
    width: "200px",
    "@media screen and (max-device-width:430px)": {
      height: "160px",
      width: "160px",
    }
  },
}));

export default useStyles;
