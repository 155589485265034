import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { formatFormData} from '../functions';
import { FIREBASE_API_URL } from './index';
import { emailTypes , adminEmail, supportPersonEmail} from '../constant';
import axios from 'axios';

const { REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_AUTH_DOMAIN, REACT_APP_FIREBASE_DB_URL, REACT_APP_PROJECT, REACT_APP_FIREBASE_STORAGE_BUCKET, REACT_APP_FIREBASE_MSG_SENDER_ID, REACT_APP_FIREBASE_APP_ID } = process.env;

const config = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DB_URL,
  projectId: REACT_APP_PROJECT,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID
};


class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage().ref();
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  // findSchoolByUrl = async (url) => {
  //   let schoolData = await this.db
  //     .collection('schools')
  //     .where('formUrl', '==', url.toLowerCase())
  //     .where('isPublished', '==', true)
  //     .get();
  //   schoolData = schoolData.docs?.[0]?.data();
  //   if (schoolData) {
  //     const groups = await this.db.collection('groups').where('school', '==', schoolData.id).get();
  //     schoolData.categories = schoolData.categories.map((category) => {
  //       const updated = { ...category };
  //       updated.groups = groups.docs
  //         .filter((group) => group.data().category === category.id)
  //         .map((group) => {
  //           const { groupName, years, isHide } = group.data();
  //           return { id: group.id, groupName, years, isHide };
  //         });
  //       return updated;
  //     });
  //   }
  //   return schoolData ? schoolData : null;
  // };

  sendEmail = async(emailParams) => {
    await axios.post(`${FIREBASE_API_URL}/sendEmail`, emailParams)
  }

  isEmailExist = async (email, schoolData = {}) => {
    const family = await this.db.collection('families').where('primaryEmail', '==', email.toLowerCase().trim()).get();
    const isUserExist = family.docs.length !== 0
    if (isUserExist) {
      const { firstName, lastName, phoneNo = '' } = family.docs[0].data().parents[0];
      let emailParams = {
        type: emailTypes.MEMBER_ALREADY_EXIST.type,
        customData: {
          ...emailTypes.MEMBER_ALREADY_EXIST.customData,
          schoolName: schoolData.schoolName,
          accountName: family.docs[0].data().familySurname,
          firstName,
          lastName,
          email: family.docs[0].data().primaryEmail,
          phoneNo
        },
        sendTo: adminEmail,
        cc: supportPersonEmail,
        isAdmin: true
      };
      await this.sendEmail(emailParams);
    }
    return isUserExist;
  }

  submitFormData = async (formData, schoolData) => {
    let formatedFamily = formatFormData(formData, schoolData);
    formatedFamily = { ...formatedFamily, primaryEmail: formatedFamily.primaryEmail.toLowerCase() };
    let familyData = await this.db.collection('families').add(formatedFamily);
    await familyData.update({ id: familyData.id });
    const { primaryEmail: userEmail } = formatedFamily;
    const { firstName, lastName } = formatedFamily.parents[0];
    // Send email to user
    let userParams = {
      type: emailTypes.USER_REGISTERED.type,
      customData: { ...emailTypes.USER_REGISTERED.customData, firstName, schoolName: schoolData.schoolName },
      sendTo: userEmail
    };
    // Send email to admin
    let adminParams = {
      type: emailTypes.NEW_USER_TO_ADMIN.type,
      customData: {
        ...emailTypes.NEW_USER_TO_ADMIN.customData,
        schoolName: schoolData.schoolName,
        accountName: formatedFamily.familySurname,
        firstName,
        lastName,
      },
      sendTo: adminEmail,
      isAdmin: true
    };
    await Promise.allSettled([
      this.sendEmail(userParams),
      this.sendEmail(adminParams)
    ]);
    return { formData, schoolData };
  };

  // updateGroupsByChildId = (childId, groupIds) => {
  //   const promises = Promise.all(
  //     groupIds.map((group) => {
  //       return this.db
  //         .collection('groups')
  //         .doc(group)
  //         .update({
  //           groupMembers: app.firestore.FieldValue.arrayUnion(childId),
  //         });
  //     })
  //   );
  //   return promises;
  // };

  // updateGroupEntriesByChildId = (childId, groupIds) => {
  //   const promises = Promise.all(
  //     groupIds.map(async (group) => {
  //       const entries = await this.db.collection('schooldataentries').where('groups', 'array-contains', group).get();
  //       let batch = app.firestore().batch();
  //       entries.docs.forEach((doc) => {
  //         batch.update(doc.ref, {
  //           groupMembers: app.firestore.FieldValue.arrayUnion(childId),
  //         });
  //       });
  //       await batch.commit();
  //     })
  //   );
  //   return promises;
  // };

  //getFamilies = () => this.db.collection('families').orderBy('familySurname').get();
}

export default Firebase;
