import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  desktopAboutYouFormRoot: {
    padding: "32px 96px 0px 124px",
    overflow: "auto",
    width: "436px"
  },
  aboutYouFormRoot: {
    margin: "24px auto",
    maxWidth: "436px",
    "@media (max-width:570px)": {
      width: "calc(100vw - 40px)",
    }
  },
  heading: {
    width: "100%",
    textAlign: "start",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "24px",
    lineHeight: 1.2,
    "@media screen and (max-device-width:599px)": {
      fontSize: "16px",
      lineHeight: 1.5,
    },
  },
  createAccount: {
    display: "inline-flex",
    width: "100%",
    marginBottom: "24px",
    "& img": {
      marginRight: "8px",
      height: "24px",
    },
    "& p": {
      textAlign: "start",
    },
  },
  space:{
    height: "12px",
    display: "block",
    "@media screen and (max-device-width:1280px)": {
      height: "8px !important",
    },
  },
  submitButton:{
    paddingBottom: "0 !important"
  }
}));

export default useStyles;
