import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import useStyles from "./FooterLinksStyles";
import ExternalLinkImg from "../../assets/external-link.png";

const FooterLinks = () => {
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.linkContainer, {[classes.rtlLinkContainer] : currentLanguage?.isRTL})}>
        <a
          rel="noreferrer"
          href={`${process.env.REACT_APP_BASE_URL}/how-to-use-the-school-calendar-assistant`}
          target="_blank"
        >
          <img src={ExternalLinkImg} alt="user_guide" />
          <span>{t("User Guide")}</span>
        </a>

      </div>
      <div className={clsx(classes.linkContainer, {[classes.rtlLinkContainer] : currentLanguage?.isRTL})}>
        <a
          rel="noreferrer"
          href={`${process.env.REACT_APP_BASE_URL}/school-terms-and-conditions`}
          target="_blank"
        >
          <img src={ExternalLinkImg} alt="user_guide" />
          <span>{t("Terms")}</span>
        </a>

      </div>
      <div className={clsx(classes.linkContainer, {[classes.rtlLinkContainer] : currentLanguage?.isRTL})}>
        <a
          rel="noreferrer"
          href={`${process.env.REACT_APP_BASE_URL}/privacy-policy`}
          target="_blank"
        >
          <img src={ExternalLinkImg} alt="user_guide" />
          <span>{t("Privacy")}</span>
        </a>

      </div>
    </div>
  );
};

export default FooterLinks;
