import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useThemeCustom from "../../hooks/useThemeCustom";
import useStyles from "./VerifyDetailsStyles";
import loadingGif from "../../assets/loading.gif";

const VerifyDetails = ({text}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { desktopMatch } = useThemeCustom();

  return (
    <div className={desktopMatch ? classes.desktopVerifyScreenRoot : classes.verifyScreenRoot}>
      <img className={classes.loadingIcon} src={loadingGif} alt="loading" />
      <Typography variant="h6">{text}</Typography>
      <Typography>{t("Please do not close your browser")}</Typography>
    </div>
  );
};

export default VerifyDetails;
