import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useThemeCustom from "../../hooks/useThemeCustom";
import AboutYouNonApiForm from "../AboutYou/AboutYouNonApiForm";
import SchoolInfo from "../SchoolInfo";
import VerifyDetails from "../VerifyDetails";
import ExistingMember from "./ExistingMember";
import SuccessScreen from "./SuccessScreen";
import useStyles from "../FormSection/FormSectionStyles";
import { useDispatch } from "react-redux";
import { withFirebase } from "../../utils/firebase";
import { resetFormData } from "../../Redux/action/formDataActions";

const NonApiSection = ({firebase}) => {
  const { t } = useTranslation();
  const schoolData = useSelector((state) => state?.childDetails.schoolData);
  const { desktopMatch, mobileMatch } = useThemeCustom();
  const [step, setStep] = useState(1);
  const [member, setMember] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();

  const submitForm = (formData) => {
    setStep(2);
    const { terms, isVisibleGroupClass, isVisibleGroupCorricular, isVisibleGroupSubject , ...data } = formData;
    const childs = data.childs.map(child => {
      return ({
        ...child,
        groupCorricular: child.groupCorricular.filter(x => x !== "none"),
        groupSubject: child.groupSubject.filter(x => x !== "none")
      })
    })
    data.childs = childs;
    firebase
      .submitFormData(data, schoolData)
        .then((res) => {
        setStep(4);
        dispatch(resetFormData());
      })
      .catch((err) => {
        setStep(1);
      });
  };

  const hide = !desktopMatch && (step === 2 || step === 3 || step === 4);
  const mobileVerify = mobileMatch && (step === 2 || step === 3 || step === 4);

  const handleIsEmailExist = () => {
    setStep(3);
  };

  const handleSubmit = (name, email) => {
    setStep(2);
    setMember({name, email});
  }

  return (
    <div
      className={desktopMatch ? classes.desktopApiSection : classes.apiSection}
    >
      {!!schoolData ? (
        <>
          <SchoolInfo
            hide={hide}
            schoolData={schoolData}
            mobileVerify={mobileVerify}
          />
          {step === 1 ? (
            <AboutYouNonApiForm submitted={handleSubmit} schoolData={schoolData} submitForm={submitForm} handleIsEmailExist={handleIsEmailExist} />
          ) : step === 2 ? (
            <VerifyDetails
              text={t("Processing your request")}
            />
          ) : step === 3 ? (
            <ExistingMember name={member.name} supportEmail={schoolData?.formData?.email}/>
          ) : step === 4 ? (
            <SuccessScreen name={member.name} schoolName={schoolData?.schoolName} email={member.email} supportEmail={schoolData?.formData?.email} />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default withFirebase(NonApiSection);
