import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../utils/constant";

const useStyles = makeStyles(() => ({
  desktopSchoolInfo: {
    minWidth: "344px",
    backgroundColor: colors.secondaryGreyLight,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    padding: "40px 42px",
    borderRadius: "20px",
    "& .MuiSkeleton-root:nth-child(1)": {
      marginBottom: "32px",
    },
    "& .MuiSkeleton-root:nth-child(2)": {
      marginBottom: "24px",
    },
    "& .MuiSkeleton-root:nth-child(3), .MuiSkeleton-root:nth-child(4)": {
      marginBottom: "12px",
    },
  },
  schoolInfo: {
    backgroundColor: colors.secondaryGreyLight,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    padding: "40px 42px",
    "& .MuiSkeleton-root:nth-child(1)": {
      marginBottom: "32px",
    },
    "& .MuiSkeleton-root:nth-child(2)": {
      marginBottom: "24px",
    },
    "& .MuiSkeleton-root:nth-child(3), .MuiSkeleton-root:nth-child(4)": {
      marginBottom: "12px",
    },
    "& .MuiSkeleton-root:nth-child(3), .MuiSkeleton-root:nth-child(4), .MuiSkeleton-root:nth-child(5)":
      {
        "@media (max-width:450px)": {
          width: "calc(100vw - 60px) !important",
        },
      },
  },
  desktopSchoolMain: {
    padding: "40px 92px 0 116px",
    "& .MuiSkeleton-root:nth-child(1)": {
      marginBottom: "24px",
    },
    "& .MuiSkeleton-root:nth-child(2), .MuiSkeleton-root:nth-child(3), .MuiSkeleton-root:nth-child(4)":
      {
        marginBottom: "12px",
      },
  },
  main: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    paddingTop: "24px",
    "& .MuiSkeleton-root:nth-child(1)": {
      marginBottom: "24px",
    },
    "& .MuiSkeleton-root:nth-child(2), .MuiSkeleton-root:nth-child(3), .MuiSkeleton-root:nth-child(4)":
      {
        marginBottom: "12px",
      },
    "& .MuiSkeleton-root:nth-child(2), .MuiSkeleton-root:nth-child(3), .MuiSkeleton-root:nth-child(4), .MuiSkeleton-root:nth-child(5)":
      {
        "@media (max-width:450px)": {
          width: "calc(100vw - 60px) !important",
        },
      },
  },
  skeleton:{
    borderRadius:"16px !important"
  }
}));

export default useStyles;
