const initialState={
    formData : null,
    loading: true
}

const formData = (state= initialState, action) =>{
    switch(action.type){
        case 'SET_FORM_DATA' : return {...state, formData: action.payload}
        case 'RESET_FORM_DATA' : return {...state, formData: null}
        case 'SET_LOADING': return {
            ...state, loading: action.loading
        }
        default: return state
    }
}
export default formData