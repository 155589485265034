import Lottie from "react-lottie";
import { makeStyles } from "@material-ui/styles";
import confettiAnimationData from "../../assets/mfh-success-confetti.json";

const useStyles = makeStyles(() => ({
    root:{
        position: "absolute",
        bottom: 0,
        right: 0,
        height: "100%",
        width: "-webkit-fill-available"
    }
}));

const SuccessConfetti = () => {
    const classes = useStyles();
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: confettiAnimationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        },
    };

    return (
        <div className={classes.root}>
            <Lottie
                options={defaultOptions}
                height="100%"
            />
        </div>
    )
}

export default SuccessConfetti;