import { useSelector } from "react-redux";
import ApiSection from "../ApiSection";
import NonApiSection from "../NonApiSection";
import FooterLinks from "../FooterLinks";
import useThemeCustom from "../../hooks/useThemeCustom";
import DashboardHeader from "../DashboardHeader";
import AboutYouSkeleton from "../AboutYou/AboutYouSkeleton";
import useStyles from "./FormSectionStyles";
import PageNotFound from "../SchoolForm/PageNotFound/PageNotFound";

const FormSection = () => {
  const schoolData = useSelector((state) => state?.childDetails.schoolData);
  const loading = useSelector((state) => state?.formData?.loading);
  const classes = useStyles();
  const { desktopMatch } = useThemeCustom();

  if (loading && Object.values(schoolData).length === 0) {
    return <AboutYouSkeleton />;
  }

  if (!loading && Object.values(schoolData).length === 0) {
    return <PageNotFound />;
  }

  return (
    <div
      className={desktopMatch ? classes.desktopContainer : classes.container}
    >
      <DashboardHeader />
      <div className={desktopMatch ? classes.desktopFormRoot : classes.formRoot}>
        {schoolData?.createdByApiSync ? <ApiSection /> : <NonApiSection />}
        {desktopMatch && <FooterLinks />}
      </div>
    </div>
  );
};
export default FormSection;
