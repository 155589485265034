import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Skeleton } from "@mui/material";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import useThemeCustom from "../../../hooks/useThemeCustom";
import LazyLoadImage from "../../LazyLoadImage/LazyLoadImage";
import useStyles from "./MaintenanceFormStyles";
import maintenanceImg from "../../../assets/maintenance.png";
import informationImg from "../../../assets/information.png";
import emailSentImg from "../../../assets/email-sent.png";
import { saveEmailToNotify } from "../../../services/api";

const MaintenanceSkeleton = () => {
  const classes = useStyles();
  const { mobileMatch } = useThemeCustom();
  return (
    <>
      {!mobileMatch && (
        <Skeleton
          className={classes.skeletonTypography}
          variant="rounded"
          width={300}
          height={42}
        />
      )}
      <Skeleton
        className={classes.skeletonSquare}
        variant="rounded"
        width={160}
        height={160}
      />
      <Skeleton
        className={classes.skeletonTypography}
        variant="rounded"
        width={mobileMatch ? 161 : 480}
        height={28}
      />
      <Skeleton
        className={classes.skeletonTypography}
        variant="rounded"
        width={mobileMatch ? "100%" : 360}
        height={16}
      />
      <Skeleton
        className={classes.skeletonTypography}
        variant="rounded"
        width={mobileMatch ? "100%" : 330}
        height={16}
      />
      <Skeleton
        className={classes.skeletonTypography}
        variant="rounded"
        width={mobileMatch ? "100%" : 300}
        height={16}
      />
    </>
  );
};

const MaintenanceFormSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const schoolData = useSelector((state) => state?.childDetails?.schoolData);
  const isRTL = currentLanguage?.isRTL;
  const [step, setStep] = useState(0);
  const { mobileMatch } = useThemeCustom();
  const initialEmail = { email: "" };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t("Invalid email address"))
      .required(t("Required")),
  });

  const notifyHandler = (email) => {
    const formData = {
      email,
      school: schoolData?.id
    }
    saveEmailToNotify(formData).then(res => {
     setStep(1);
    }).catch(err => {
      console.log("()=>err", err)
    })
  };

  return step === 1 ? (
    <>
      <img
        src={emailSentImg}
        className={classes.emailSentImg}
        alt="email_sent"
      />
      <Typography className={classes.h3}>
        {t("We’ll email you when we’re back online.")}
      </Typography>
      <Typography className={classes.h4}>{t("It won’t be long.")}</Typography>
    </>
  ) : (
    <>
      <Typography className={classes.title}>
        {t("We’ll be back shortly...")}
      </Typography>
      <LazyLoadImage
        loadingClass={classes.loadingImageContainer}
        className={classes.imageContainer}
        alt="maintenance_image"
        src={maintenanceImg}
      />
      <Typography className={classes.h1}>
        {t("You’ve caught us making My Family Hand even better!")}
      </Typography>
      <Typography className={classes.h2}>
        {t("Enter your email address below and we’ll notify you as soon as our maintenance period has ended.")}
      </Typography>
      <div className={classes.emailField}>
        <Formik
          enableReinitialize
          initialValues={initialEmail}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            notifyHandler(values.email)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    value={values.email}
                    name="email"
                    placeholder={t("Your email address*")}
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    disabled={step === 1}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    InputProps={{
                      className: clsx(classes.textField, {[classes.rtlTextField] : isRTL} ),
                      endAdornment:
                        !mobileMatch && (step === 0 || step === 1) ? (
                          <InputAdornment position="end">
                            <Button
                              onClick={handleSubmit}
                              type="submit"
                              variant="contained"
                              className={classes.notifyButton}
                            >
                              {t("Notify Me")}
                            </Button>
                          </InputAdornment>
                        ) : (
                          <></>
                        ),
                    }}
                  />
                </FormControl>
                {mobileMatch && (step === 0 || step === 1) && (
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                    className={classes.notifyButton}
                  >
                    {t("Notify Me")}
                  </Button>
                )}
              </form>
            );
          }}
        </Formik>
      </div>
      <div className={clsx(classes.information,  {[classes.rtlInformation]: isRTL})}>
        <img src={informationImg} alt="information" />
        <Typography>
          {t("By submitting your email address you grant us permission to notify you when our scheduled maintenance period has ended. Your email address will be permanently deleted when the notification has been sent.")}
        </Typography>
      </div>
    </>
  );
};

const MaintenanceForm = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  },[])

  return (
    <div className={classes.maintenanceScreen}>
      {isLoading ? <MaintenanceSkeleton /> : <MaintenanceFormSection />}
    </div>
  );
};

export default MaintenanceForm;
