import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import useThemeCustom from "../../../hooks/useThemeCustom";
import useStyles from "./SuccessScreenStyles";
import ApprovedImg from "../../../assets/Step 3@2x.png";
import EmailImg from "../../../assets/Step 1@2x.png";
import aboutIcon from "../../../assets/aboutIcon.png";
import SuccessConfetti from "../../SuccessConfetti";

const SuccessScreen = ({name, schoolName, email, supportEmail}) => {
    const { t } = useTranslation();
    const currentLanguage = useSelector((state) => state?.childDetails?.language);
    const { desktopMatch } = useThemeCustom();
    const classes = useStyles();

    return (
        <div className={desktopMatch ? classes.desktopSuccessScreenRoot : classes.successScreenRoot}>
            <Typography className={classes.heading}>{t("Welcome firstName!", {firstName: name})} </Typography>
            <Typography className={classes.description}>{t("We are now verifying your access request with schoolName.", {schoolName})}</Typography>
            <div className={classes.stepList}>
                <div className={clsx(classes.step, {[classes.rtlStep]: currentLanguage?.isRTL})}>
                    <img src={ApprovedImg} alt="approved_img"/>
                    <div>
                        <Typography className={classes.heading2}>{t("Expect approval in less than 24hrs.")}</Typography>
                        <Typography className={classes.description2}>{t("Once approved, you will receive an email with instructions for accessing your diary.")}</Typography>
                    </div>
                </div>
                <div className={clsx(classes.step, {[classes.rtlStep]: currentLanguage?.isRTL})}>
                    <img src={EmailImg} alt="email_img" />
                    <div>
                        <Typography className={classes.heading2}>
                        <Trans
                            i18nKey="Registration confirmation has been sent to"
                            values={{email: email}}
                            components={{
                                mail: <span className={classes.description2}>email</span>
                            }}
                        />
                        </Typography>
                    </div>
                </div>
            </div>
            <img className={classes.aboutIcon} src={aboutIcon} alt="about_icon" />
            <Typography className={classes.instructions}>{t("If you have not received your registration confirmation email after a short while, check your spam folder and Promotions tab (Gmail users) and please mark it as ‘not spam’ or drag it to your inbox to ensure you don’t miss future important emails from us.")}</Typography>
            <Typography className={classes.instructions + " lastInstruction"}>
                <Trans
                    i18nKey="For assistance, email supportEmail."
                    values={{ supportEmail:  supportEmail}}
                    components={{ a: <a target="_blank" href={`mailto:${supportEmail}`} rel="noreferrer">{supportEmail}</a> }}
                />
            </Typography>
            <SuccessConfetti/>
        </div>
    )
}

export default SuccessScreen;