import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../utils/constant";

const useStyles = makeStyles(() => ({
    desktopExistingMember:{
        padding: "32px 80px 32px 108px",
        position: "relative",
        overflowY: "auto",
        "& *": {
          textAlign: "start",
        },
    },
    existingMember:{
        margin: "24px auto",
        position: "relative",
        maxWidth: "460px",
        "& *": {
          textAlign: "start",
        },
        "@media (max-width:500px)": {
          width: "calc(100vw - 40px)",
        },
        "@media (max-width:450px)": {
          width: "calc(100vw - 40px)",
        },
        "@media (max-width:599px)": {
          position: "initial !important",
        },
    },
    heading: {
        fontSize: "24px",
        fontWeight: "bold",
        marginBottom: "24px",
    },
    existingContainer:{
        padding: "16px 12px",
        border: `1px solid ${colors.tertiaryConfirm}`,
        borderRadius: "8px",
        background: colors.background,
        marginBottom: "24px",
        "& p":{
            fontSize: "16px",
            lineHeight: 1.75,
            fontWeight: "bold",
            "@media (max-width:500px)": {
              whiteSpace: "wrap",
            }
        }
    },
    description:{
        fontSize: "16px",
        lineHeight: 1.75,
        marginBottom: "24px",
        "& a":{
          textDecoration: "none",
          color: colors.dark,
          zIndex: 1,
          position: "sticky"
        }
    },
    heading2:{
        fontSize: "16px",
        lineHeight: 1.75,
        fontWeight: "bold"
    }
}))

export default useStyles;