import { makeStyles } from "@material-ui/styles";
import { colors } from "../../utils/constant";
const useStyles = makeStyles(() => ({
    desktopContainer: {
        background: colors.rootBackground,
        height: "100vh",
        overflow: "auto",
        "@media screen and (min-device-width: 1280px) and (max-device-height: 820px)": {
          height: "auto !important",
        },
      },
      container: {
        background: colors.rootBackground,
        height: "auto",
        overflow: "auto",
      },
      desktopFormRoot:{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "calc(100% - 96px)",
        "@media screen and (max-device-width: 1280px)":{
          marginTop: "124px",
          justifyContent: "flex-start",
          padding: "163px 107px",
          height: "100%"
        },
        "@media screen and (max-device-width: 600px)":{
          marginTop: "138px",
          justifyContent: "flex-start",
          padding: "0",
          height: "100%"
        },
        "@media screen and (max-device-width: 280px)":{
          marginTop: "155px"
        }
      },
      formRoot: {
        display: "flex",
        flexFlow: "column",
        height: "calc(100% - 114px)",
        marginTop: "114px",
        background: colors.white
      },
}));

export default useStyles;