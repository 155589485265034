import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useThemeCustom from "../../hooks/useThemeCustom";
import { Select, FormControl, Popper, ClickAwayListener } from "@mui/material";
import { MenuItem, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import clsx from "clsx";
import FooterLinks from "../FooterLinks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useStyles from "./DashboardHeaderStyles";
import { fetchLanguageList } from "../../services/api";
import { setLanguage } from "../../Redux/action/familyActions";
import { setHelpSection } from "../../Redux/action/familyActions";
import myfamilyhandLogodesktop from "../../assets/myfamilyhandLogo.png";
import myfamilyhandLogo from "../../assets/myfamilyhandLogo1.png";
import helpFrame from "../../assets/helpFrame.png";
import helpFrameDark from "../../assets/helpFrame1.png";
import translateIcon from "../../assets/icn-translate.png";
import highlightImage from "../../assets/Highlight_05.png";
import LazyLoadImage from "../LazyLoadImage/LazyLoadImage";
import { filterLanguageList } from "../../utils/functions";

const ChooseLanguage = () => {
  const dispatch = useDispatch();
  const { i18n: {changeLanguage, language} } = useTranslation();
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const schoolData = useSelector((state) => state?.childDetails?.schoolData);
  const isRTL = currentLanguage?.isRTL;
  const { desktopMatch } = useThemeCustom();
  const [languages, setLanguages] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const fetchLanguages = async () => {
      const data = await fetchLanguageList();
      const sortedData = sortLanguages(data);
      setLanguages(sortedData);
    }
    fetchLanguages();
  },[]);

  const languageHandler = (value) => {
    const selectedLanguage = languages.find(x => x.code === value);
    dispatch(setLanguage(selectedLanguage));
  };

  const sortLanguages = (array) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a,b) => {
      if(a[0].code === "en"){
        return -1;
      }else if(b[0].code === "en"){
        return 1;
      }else {
        if(a[0].name < b[0].name) { return -1; }
        if(a[0].name > b[0].name) { return 1; }
      }
      return 0;
    });
    return stabilizedThis.map((el) => el[0]);
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={clsx(desktopMatch ? classes.desktopSelectForm : classes.selectForm, currentLanguage?.isRTL ? classes.rtlSelectForm:"")}
      focused={false}
    >
      <Select
        variant="outlined"
        value={language}
        onChange={(e) => {
          languageHandler(e.target.value);
          changeLanguage(e.target.value);
          if(!isChanged){
            setIsChanged(true);
          }
        }}
        defaultValue="en"
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          PaperProps: { className: desktopMatch ? classes.desktopPaper: classes.paper },
          MenuListProps: { className: clsx(classes.list, {[classes.nonPaidList]: !schoolData?.paidTranslationEnabled }) },
        }}
        name="language"
        className={clsx(classes.select, {[classes.rtlSelect] : isRTL})}
        placeholder="Choose language"
        startAdornment={<img src={translateIcon} alt="translateIcon" className="translateIcon" />}
        renderValue={(selected) => {
          const selectedLanguage = languages.find(x => x.code === selected);
          if(isChanged && selectedLanguage){
            return (
              <MenuItem value={selectedLanguage.code} key="none">
              <span>{selectedLanguage.name}</span>
            </MenuItem>
            )
          }
          return (
            <MenuItem value="0" key="none">
              <span>Choose language</span>
            </MenuItem>
          );
        }}
      >
        {sortLanguages(filterLanguageList(languages, schoolData?.paidTranslationEnabled)).map((l) =>{
           return (
            <MenuItem
              classes={{ selected: classes.selected, root: classes.menuRoot }}
              value={l.code}
              key={l.code}
            >
              <span className="label">
                <span >{l?.label} {l.code !== "en" &&  <span className="languageName"> &#10088;{l.name}&#10089;</span>}</span>
                {l.code === currentLanguage.code && <CheckIcon className="selectedLanguageCheck" />}
              </span>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  );
};

const DashboardHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schoolData = useSelector((state) => state?.childDetails?.schoolData);
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const isRTL = currentLanguage?.isRTL;
  const anchorEl = useSelector((state) => state?.childDetails?.anchorEl);
  const maintenanceMode = useSelector((state) => state?.maintenance?.mode);
  const { mobileMatch, desktopMatch, tabletMatch } = useThemeCustom();
  const classes = useStyles();

  const handleClick = (event) => {
    dispatch(setHelpSection(anchorEl ? null :event.currentTarget));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    if(open){
      dispatch(setHelpSection(null));
    }
  }

  return (
    <div className={desktopMatch ? classes.desktopRoot : classes.root}>
      <ClickAwayListener onClickAway={handleClose} >
      <div
        className={desktopMatch ? classes.desktopHeaderTop : classes.headerTop}
      >
        <div className={classes.equalWidth} style={{justifyContent: "start"}}>
        <img
          className={classes.logo}
          src={desktopMatch ? myfamilyhandLogodesktop : myfamilyhandLogo}
          alt="myfamilyhandLogo"
        />
        </div>
        <div className={classes.equalWidth} style={{justifyContent: "center"}}>
        {desktopMatch && <ChooseLanguage />}
        </div>
        <div className={classes.equalWidth} style={{justifyContent: "end"}}>
        <div
          className={
            clsx(desktopMatch
              ? classes.desktopHelpSection
              : mobileMatch
              ? clsx(classes.mobileHelpSection, {[classes.rtlMobileHelpSection]: isRTL})
              : classes.helpSection, {[classes.rtlHelSection]: isRTL})
          }

          onClick={handleClick}
        >

          <Typography aria-describedby={id} >
            {open ? t("Close"): mobileMatch ? t("Help ?") : t("Need help?") }{" "}
          </Typography>
          {!mobileMatch && <img className="helpFrame" src={desktopMatch ? helpFrame: helpFrameDark} alt="helpFrame" />}
        </div>
        </div>
        <Popper
            transition
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="right-start"
            className={desktopMatch ? isRTL ? classes.desktopPaperClassRtl :  classes.desktopPaperClass : classes.paperClass}
          >
            <div className={classes.helpMenu}>
              <div className={clsx(classes.helpMenuContainer, {[classes.helpMenuContainerRtl]: isRTL})}>
                <div className={classes.supportPerson}>
                  <img
                    className="highlightImg"
                    src={highlightImage}
                    alt="highlight_img"
                  />
                  <LazyLoadImage
                  className="michelleImg"
                  src={schoolData?.formData?.schoolSupportPerson?.url}
                    loadingClass={classes.supportPersonImage}
                    alt="michelle_img" />
                </div>
                <Typography className="supportPerson">{t("Hi! I’m supportPersonName.", {supportPersonName: schoolData?.formData?.firstName })}</Typography>
              </div>
              <Typography>{t("Need help? Please email us")}</Typography>
              <Typography className="supportEmail">
                <a href={`mailto:${schoolData?.formData?.email}`}>
                  {`${schoolData?.formData?.email}`}
                </a>
              </Typography>
              {!maintenanceMode && schoolData?.createdByApiSync && (
                <div className={classes.apiSection}>
                  <Typography>{t("Email not recognised?")}</Typography>
                  <Typography>
                    {t("Please ensure your email address is the same as what the Dept of Education (and your school) have saved on file.")}
                  </Typography>
                  <Typography>
                    {t("If you are unsure of what this is, please contact your school first to confirm.")}
                  </Typography>
                  <Typography>
                    {t("If you wish to update your email address, they can do this for you. Once done, please wait at least 24 hours and then come back to create your account (once the sysytems have updated at the Dept. of Education).")}
                  </Typography>
                  <Typography>
                    {t("If you believe your email is correct, there may be another issue. Please contact your school.")}
                  </Typography>
                </div>
              )}
              {!desktopMatch && (
                <div className={classes.links}>
                  <FooterLinks />
                </div>
              )}
            </div>
          </Popper>
      </div>
      </ClickAwayListener>
      {(mobileMatch || tabletMatch) && <ChooseLanguage />}
    </div>
  );
};

export default DashboardHeader;
