import CryptoJS from 'crypto-js';
import moment from 'moment';
import { encryptedPasswordSecret, year as schoolYears, familyChildStatus } from './constant';

export const formatFormData = (formData, schoolData) => {
  schoolData.freeCalendars = schoolData.freeCalendars.sort();
  const school = {
    id: schoolData.id,
    schoolName: schoolData.schoolName,
    years: schoolData.freeCalendars.map((year) => schoolYears.find((yearData) => yearData.value === year)),
    color: '',
  };
  const childs = formatChilds(formData.childs, school);
  const createdDate = moment.utc().format();
  const familySurname = setSurname(formData.childs);
  const formatted = {
    childs,
    childsSchools: [],
    country: formData.country,
    createdDate,
    createdDateTimestamp: new Date(createdDate),
    encryptedPassword: '',
    familySurname,
    aliasFamilySurname: familySurname.toLowerCase(),
    freeHost: 'true',
    id: generateFileHash(20),
    myWhanauEmail: '',
    parents: formatParents(formData),
    password: '',
    planStatus: '',
    planType: 'Free',
    primaryEmail: formData.email.toLowerCase().trim(),
    schoolIds: formData.childs.map(() => school.id),
    schools: formData.childs.map((child) => ({
      color: school.color,
      schoolName: school.id,
      year: child.year,
      years: school.years,
    })),
    timezone: formData.timezone,
    userId: '',
    userName: '',
    termsChecked: moment.utc().format(),
    accountStatus: childs.map(child => child.status),
    status: familyChildStatus[0],
    statusUpdatedAt: moment.utc().format(),
    childYears: childs.map(child => child.year),
  };
  return formatted;
};

const formatParents = (formData) => {
  return [
    {
      id: generateFileHash(20),
      emailAddress: formData.email.toLowerCase().trim(),
      phoneNo: formData.phoneNo,
      firstName: formData.firstName,
      lastName: formData.lastName,
      initials: `${(formData.firstName[0] || '')}${(formData.lastName[0] || '')}`,
    }
  ];
};

const formatChilds = (childs, school) => {
  return childs.map((child) => {
    const formated = {
      createdDate: moment.utc().format(),
      status: familyChildStatus[0],
      statusUpdatedAt: moment.utc().format(),
      id: generateFileHash(20),
      firstName: child.firstName,
      group: '',
      groups: [child.groupClass, ...child.groupCorricular.filter((g) => g !== 'none'), ...child.groupSubject],
      initials: `${(child.firstName[0] || '')}${(child.lastName[0] || '')}`,
      lastName: child.lastName,
      password: '',
      school: school.id,
      schoolPortal: '',
      schoolPortalLogin: '',
      schoolPortalPassword: '',
      userName: '',
      year: child.year,
      years: school.years,
    };
    return formated;
  });
};

const setSurname = (childs) => {
  let surname = childs?.[0]?.lastName || '';
  if (childs.length > 1 && childs[0].lastName !== childs[1].lastName) surname = `${surname}-${childs[1].lastName}`;
  return surname;
};

export const encryptSHA256 = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(text, encryptedPasswordSecret));
};

export const encrypt = (text) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};

export const decrypt = (data) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

export const generateFileHash = (length = 9) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

//start generate password 8 characters, one uppercase letter, one lowercase letter and one number.
// Ref : https://stackoverflow.com/questions/20856822/javascript-random-password-with-specific-format

let upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
let lower = 'abcdefghijklmnopqrstuvwxyz';
let digit = '0123456789';
// let spacialChar = '!@#$%^&*(()_'
let all = upper + lower + digit;

function rand(max) {
  return Math.floor(Math.random() * max);
}

function random(set) {
  return set[rand(set.length - 1)];
}

function generate(length, set) {
  let result = [];
  while (length--) result.push(random(set));
  return result;
}

function shuffle(arr) {
  let result = [];

  while (arr.length) {
    result = result.concat(arr.splice(rand[arr.length - 1]));
  }

  return result;
}

export const generatePatternPassword = (length) => {
  let result = []; // we need to ensure we have some characters

  result = result.concat(generate(1, upper)); // 1 upper case
  result = result.concat(generate(1, lower)); // 1 lower case
  result = result.concat(generate(1, digit)); // 1 digit
  // result = result.concat(generate(1, spacialChar)) // 1 spacial char
  result = result.concat(generate(length - 3, all)); // remaining - whatever

  return shuffle(result).join(''); // shuffle and make a string
};

//end generate password 8 characters, one uppercase letter, one lowercase letter and one number.

/** To generate common params for sending email request */
export const getCommonEmailParamas = ({ type, customData }) => ({
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  body: { type, customData }
});

export const filterLanguageList = (languages, isPaid) => {
  if(isPaid){
    return languages;
  }else{
    return languages.filter(x => x.pack === "free")
  }
};