import { Checkbox, Typography } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../../utils/constant";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  checkboxRootContainer: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    padding: "0 2px",
    "& svg": {
      width: "32px",
      height: "32px",
    },
    "& span": {
      padding: "0px !important",
    }
  },
  checkboxRoot: {
    color: `${colors.border} !important`,
    marginRight: "6px",

  },
  checked: {
    color: `${colors.primary} !important`,
  },
  condition:{
    fontSize: "14px",
    textAlign: "left",
    //maxWidth: "88%",
    lineHeight: "28px",
    "@media (max-width:460px)": {
      fontSize: "12px",
    },
    "& span":{
      fontWeight: "600"
    },
    "& a":{
      textDecoration: "none",
      fontWeight: "600",
      color: colors.dark
    },
    "@media (max-width:375px)": {
      lineHeight: "18px",
    }
  },
  error:{
    color: colors.tertiaryRed,
    fontSize: "12px",
    fontWeight: 500,
    marginLeft: "6px",
    marginRight: "auto",
    lineHeight: 1.67
  },
  root:{
    display: "inline-flex",
    width: "100%"
  }
}));

const CheckBox = ({ condition, checked, handleTermCheck, error, customClass }) => {
  const classes = useStyles();
  return (
    <>
    <div className={clsx(customClass, classes.checkboxRootContainer)}>
      <div className={classes.root}>
      <Checkbox
        checked={checked}
        icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
        checkedIcon={<CheckBoxIcon fontSize="large" />}
        onClick={handleTermCheck}
        classes={{ checked: classes.checked, root: classes.checkboxRoot }}
        size="small"
      />

        <Typography className={classes.condition}>{condition}</Typography>
      </div>
        <span className={classes.error}>{error}</span>
    </div>
    </>
  );
};

export default CheckBox;
