import { useSelector } from "react-redux";
import clsx from "clsx";
import { FormControl, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../../utils/constant";
const useStyles = makeStyles(() => ({
  textField: {
    "& input": {
      fontSize: "16px",
      lineHeight: 1.75,
      "@media (max-width:460px)": {
        fontSize: "14px",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${colors.border} !important`,
      color: `${colors.typography1} !important`,
    },
    "& .MuiFormLabel-root.Mui-error": {
      color:`${colors.border3} !important`,
    },
  },
  inputRoot: {
    color: colors.border3,
    "& .MuiFormLabel-asterisk.Mui-error": {
      color: `${colors.border3} !important`,
    },
  },
  inputBaseRoot: {
    height: "48px",
  },
  inputBaseRootRtl: {
    height: "48px",
    "& legend": {
      textAlign: "end",
    },
  },
  error: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${colors.tertiaryRed} !important`,
    },
  },
  shrink: {
    color: `${colors.primary}`,
    transform: "translate(14px, -6px) scale(0.75) !important",
    "@media (max-width:460px)": {
      transform: "translate(15px, -5px) scale(0.85)",
    },
  },
  rtlShrink: {
    color: `${colors.primary}`,
    transformOrigin: "top right",
    transform: "translate(-14px, -6px) scale(0.75) !important",
    "@media (max-width:460px)": {
      transform: "translate(-14px, -4px) scale(0.85) !important",
    },
  },
  focused: {
    color: `${colors.primary} !important`,
  },
  root: {
    color: `${colors.border3}`,
    transform: "translate(14px, 16px) scale(1)",
    "@media (max-width:460px)": {
      fontSize: "14px",
    },
    fontSize: "16px",
    fontWeight: 500,
  },
  rtlRoot: {
    color: `${colors.border3}`,
    transform: "translate(-14px, 16px) scale(1)",
    right: "0 !important",
    left: "auto !important",
  },
  helperText: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: 1.67,
    margin: "4px 0 0",
    "& span": {
      fontWeight: "bold",
    },
  },
  helperTextError: {
    color: `${colors.tertiaryRed} !important`,
  },
  rtlHelperTextError: {
    textAlign: "right",
    color: `${colors.tertiaryRed} !important`,
  },
}));

const TextFieldContainer = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  helperText,
  error,
  required,
}) => {
  const classes = useStyles();
  const currentLanguage = useSelector((state) => state?.childDetails?.language);
  const isRTL = currentLanguage?.isRTL;
  return (
    <FormControl fullWidth>
      <TextField
        autoComplete="none"
        variant="outlined"
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        helperText={helperText}
        error={error}
        className={clsx(classes.textField, "responsive-lineHeight")}
        FormHelperTextProps={{
          classes: {
            root: classes.helperText,
            error: isRTL ? classes.rtlHelperTextError : classes.helperTextError,
          },
        }}
        InputLabelProps={{
          classes: {
            shrink: isRTL ? classes.rtlShrink : classes.shrink,
            focused: classes.focused,
            root: isRTL ? classes.rtlRoot : classes.root,
          },
        }}
        InputProps={{
          classes: {
            root: isRTL ? classes.inputBaseRootRtl : classes.inputBaseRoot,
            error: classes.error,
          },
        }}
        classes={{ root: classes.inputRoot }}
        required={required}
      />
    </FormControl>
  );
};

export default TextFieldContainer;
