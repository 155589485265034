import ContentLoader from "react-content-loader";

const ImageSkeletonLoader = ({ loadingStyle, loadingClass = "" }) => {
  const { width = 200, height = 200, radius = 10 } = loadingStyle || {};
  return (
    <div className={loadingClass}>
      <ContentLoader
        speed={2}
        width={width}
        height={height}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect
          x="0"
          y="0"
          rx={radius}
          ry={radius}
          width={width}
          height={height}
        />
      </ContentLoader>
    </div>
  );
};

export default ImageSkeletonLoader;
