import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../../utils/constant";

const useStyles = makeStyles(() => ({
  buttonRoot: {
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "bold",
    width: "100%",
    height: "48px",
    background: colors.primary,
    boxShadow: "none !important",
    margin: "24px 0",
    "&:hover":{
      background: colors.primary,
    },
    "@media (max-width:460px)": {
      fontSize: "14px",
    },
  }
}));

const ButtonComponent = ({ onClick, children, disabled }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.buttonRoot}
      onClick={onClick}
      variant="contained"
      disabled={disabled}
      color="primary"
    >
      {children}
    </Button>
  );
};

export default ButtonComponent;
